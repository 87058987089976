import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {doLogout} from "../../services/conem-services";
import PerfilPanel from "../login/PerfilPanel";
import {Notificacion} from "../../types/servicio";
import {getUsuarioSesion, isRequeridoCambioContrasenia} from "../../utils";

const Navbar = () => {

    const navigate = useNavigate();


    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const loggedInUser = getUsuarioSesion();
        if (loggedInUser) {
            setAuthenticated(true);
        } else {
            navigate('/')
        }

    }, []);

    async function logout() {
        await doLogout();
        navigate('/')
    }

    const [showProfilePanel, setShowProfilePanel] = useState<boolean>(false);
    const openProfilePanel = () => setShowProfilePanel(true);

    const closeProfilePanel = () => setShowProfilePanel(false);

    return (<nav className="navbar navbar-light"
                 style={{background: "white", border: "1px solid lightgray"}}>
        <a className="navbar-brand" href="#" onClick={()=>navigate('/home')} style={{marginTop: '0px', paddingTop: '0px'}}>
            <img src="/logo.jpg" alt="" width="220" height="100">
            </img>
        </a>
        {(authenticated && !isRequeridoCambioContrasenia()) &&
            <div className="d-flex align-items-center" style={{marginRight: "30px"}}>

                <Dropdown>
                    <Dropdown.Toggle variant="light" className="text-reset me-3 dropdown-toggle"
                                     href="#"
                                     id="navbarDropdownMenuAvatar"
                                     role="button"
                                     data-toggle="dropdown"
                                     aria-expanded="false">
                        <span className="bi-person-circle fw-bold"/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#" onClick={openProfilePanel}>Mi perfil</Dropdown.Item>
                        <Dropdown.Item href="#" onClick={logout}>Cerrar sesión</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        }

        <PerfilPanel show={showProfilePanel} close={closeProfilePanel}/>

    </nav>)
}

export default Navbar;
