import {RotatingLines} from "react-loader-spinner";
import React from "react";

export default function LoadingSpinner(props:{label?:string}) {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center">
                <RotatingLines
                    strokeColor="grey"
                    visible={true}
                    width="50"
                    strokeWidth="5"
                    animationDuration="0.75"
                    ariaLabel="rotating-lines-loading"
                />
            </div>
            {
                props.label && <div className='d-flex align-items-center justify-content-center fw-bold d-flex'
                                    style={{color: '#747171'}}>
                    {props.label}
                </div>
            }

        </>
    )
}