import {Accordion, Button, Card, Col, Container, Form, Row, Table} from "react-bootstrap";

import TotalLabel from "./TotalLabel";
import React, {useEffect, useRef, useState} from "react";
import {
    generarFolioCliente,
    getCatalogoServicios,
    getConfiguracionSistema, getCsrfToken,
    listGroups,
    registrarServicioPrueba,
    uploadTmpFile, actualizarConstanciaSituacionFiscal, actualizarDocumentoCotizacion,
} from "../../services/conem-services";
import CatalogoServicioPanel from "./CatalogoServicioPanel";
import {Archivo, CatalogoServicio, Grupo, Servicio, Subservicio, NamedItem} from "../../types/servicio";
import DeleteButton from "../common/DeleteButton";
import PageHeader from "../common/PageHeader";
import ModalMessage from "../common/ModalMessage";
import {
    dateToSimpleFormat, FILE_MAX_SIZE,
    formatoNumeroFolio,
    getSumaDescuentoSubservicios,
    getSumaPrecioSubservicios,
    getSumaSubtotalSubservicios,
    getSumaTotalServicios,
    isAdmin,
    isCorreoValido,
    FOLIO_PREFIX,
    OK,
    toDate,
    truncarTexto, NOT_FOUND
} from "../../utils";
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import CatalogoServicioModal from "../catalogo-servicio/CatalogoServicioModal";
import cloneDeep from 'lodash.clonedeep';
import {omit, toInteger} from "lodash";
import DatePicker from "react-datepicker";
import FileChooser from "../documento/FileChooser";
import DiasSemana from "./DiasSemana";
import {RotatingLines} from "react-loader-spinner";
import FileSelector from "../FileSelector/FileSelector";
import LoadingSpinner from "../common/LoadingSpinner";
import FormCotizacion from "../FormCotizacion/FormCotizacion";
import {getFolio, guardarCotizaciones} from "../../services/cotizacion.services";
import {getClienteByEmail} from "../../services/cliente.services";
import {useSecuencias} from "../../hooks/useSecuencias";


export default function FormNuevaCotizacion() {

    const [catalogoPrefijoFolio, setCatalogoPrefijoFolio] = useState<string | null>(null);
    const [claveFolio, setClaveFolio] = useState('')
    const [secuenciaFolioActual, setSecuenciaFolioActual] = useState<number>(1);


    const secuencias = useSecuencias();

    const [constanciaSituacionFiscal, setConstanciaSituacionFiscal] = useState<any>(null);
    const [documentoCotizacionFile, setDocumentoCotizacionFile] = useState<any>(null);


    const [listaCotizaciones, setListaCotizaciones] = useState<Servicio[]>([]);
    const [servicioProteccionCivil, setServicioProteccionCivil] = useState<boolean>(true);


    const [documentoCotizacion, setDocumentoCotizacion] = useState<Archivo | null>(null);
    const [constanciaFiscal, setConstanciaFiscal] = useState<Archivo | null>(null);
    const [vistoBuenoAniosAnteriores, setVistoBuenoAniosAnteriores] = useState<boolean>(false);
    const [vigenciaVistoBueno, setVigenciaVistoBueno] = useState<Date | null>(null);
    const [representanteLegalInmueble, setRepresentanteLegalInmueble] = useState<string>('');
    const [nombrePersonalRecibiraInspector, setNombrePersonalRecibiraInspector] = useState<string>('');
    const [nivelesConstruccion, setNivelesConstruccion] = useState<string>('');
    const [horarioLaboral, setHorarioLaboral] = useState<string>('');
    const [diasLaborales, setDiasLaborales] = useState<NamedItem[]>([
        {
            name: 'Domingo', enabled: false
        },
        {
            name: 'Lunes', enabled: true
        },
        {
            name: 'Martes', enabled: true
        },
        {
            name: 'Miércoles', enabled: true
        },
        {
            name: 'Jueves', enabled: true
        },
        {
            name: 'Viernes', enabled: true
        },
        {
            name: 'Sábado', enabled: false
        },
    ]);
    const changeSelectionDay = (index: number, value: boolean) => {
        setDiasLaborales(
            diasLaborales.map((day: NamedItem, i: number) => {
                if (i === index) {
                    day.enabled = value;
                }
                return day;
            })
        )
    }
    const [errores, setErrores] = useState<any>({});
    const [loading, setLoading] = useState(false)
    const [catalogoServicioSeleccionado, setCatalogoServicioSeleccionado] = useState<CatalogoServicio | null>(null);
    const [catalogoServicioEditable, setCatalogoServicioEditable] = useState<CatalogoServicio | null>(null);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState<Grupo | null>(null);
    const [listaCatalogoServicio, setListaCatalogoServicio] = useState<CatalogoServicio[]>([]);
    const [catalogoServicioPaginado, setCatalogoServicioPaginado] = useState<CatalogoServicio[]>([]);
    const [indicePaginaCatalogoServicio, setIndicePaginaCatalogoServicio] = useState<number>(-1);
    const [showCatalogoServicioPanel, setShowCatalogoServicioPanel] = useState<boolean>(false);
    const [textoButtonEnviar, setTextoButtonEnviar] = useState("Enviar cotización");

    const [nombreContacto, setNombreContacto] = useState<string>('');
    const [telefonoContacto, setTelefonoContacto] = useState<string>('');
    const [correoContacto, setCorreoContacto] = useState('');
    const [nombreComercial, setNombreComercial] = useState('');
    const [direccionExacta, setDireccionExacta] = useState('');
    const [giroComercial, setGiroComercial] = useState('');
    const [metrosConstruccion, setMetrosConstruccion] = useState('');
    const [metrosTerreno, setMetrosTerreno] = useState('');
    const [numeroEmpleados, setNumeroEmpleados] = useState('');
    const [sustanciasQuimicosPeligrosos, setSustanciasQuimicosPeligrosos] = useState('');
    const [gasCapacidadAlmacenamiento, setGasCapacidadAlmacenamiento] = useState('');
    const [nivelRiesgo, setNivelRiesgo] = useState('bajo');
    const [tipoCliente, setTipoCliente] = useState<string>('nuevo');
    const [razonSocial, setRazonSocial] = useState('');
    const [rfc, setRfc] = useState('');
    const [domicilioFiscal, setDomicilioFiscal] = useState('');
    const [requiereFactura, setRequiereFactura] = useState<boolean>(false);
    const [total, setTotal] = useState(0);
    const [porcentajeIva, setPorcentajeIva] = useState<number>(16);
    const [isLoadingCatalogo, setIsLoadingCatalogo] = useState<boolean>(false);


    const cargarCatalogoServicio = () => {

        setIsLoadingCatalogo(true);

        getCatalogoServicios({page: 0, rowsPerPage: 200}).then(data => {
            if (data.numberOfElements > 0) {
                const catalogoServicios = data.items;
                for (let servicio of catalogoServicios) {
                    const sumaDescuentos = servicio?.subservicios.reduce((accumulator: number, subservice: Subservicio) => {
                        return accumulator + (subservice.precio * (subservice.porcentajeDescuento / 100));
                    }, 0);
                    const sumaPrecios = servicio?.subservicios.reduce((accumulator: number, subservice: Subservicio) => {
                        return accumulator + subservice.precio;
                    }, 0);
                    servicio.precio = sumaPrecios;
                    servicio.descuento = sumaDescuentos;
                    servicio.iva = (sumaPrecios - sumaDescuentos) * (porcentajeIva / 100.0);
                    servicio.subtotal = (sumaPrecios - sumaDescuentos);
                }
                data.items = catalogoServicios;

                const capacidad = 3;
                setCatalogoServicioPaginado(data.items.filter((item: CatalogoServicio, index: number) => {
                    if (index < capacidad) {
                        return item;
                    }
                }));
                setIndicePaginaCatalogoServicio(data.numberOfElements > capacidad ? 2 : (data.numberOfElements - 1));
                cambiarSeleccionCatalogoServicio(data.items[0]);
                setListaCatalogoServicio(data.items);
            }
        }).catch(error => {
            showError('Ha ocurrido un error al intentar cargar el catálogo de servicios');
        }).finally(() => {
            setIsLoadingCatalogo(false);
        });


        getConfiguracionSistema().then(data => {
            setPorcentajeIva(data.porcentajeIva);
        }).catch(error => {
            console.log(error)
        });

        listGroups().then(data => {
            setGrupos(data);
            if (data.length > 0) {
                setGrupoSeleccionado(data[0]);
            }
        }).catch(error => {
            console.log(error)
        })

    }

    useEffect(() => {
        cargarCatalogoServicio();
    }, []);

    const limpiarFormulario = () => {
        setSecuenciaFolioActual(1);
        secuencias.clear();


        setDocumentoCotizacionFile(null);
        setClaveFolio('');
        setNombreContacto('');
        setTelefonoContacto('');
        setCorreoContacto('');
        setNombreComercial('');
        setDireccionExacta('');
        setGiroComercial('');
        setMetrosConstruccion('');
        setMetrosTerreno('');
        setNumeroEmpleados('');
        setSustanciasQuimicosPeligrosos('');
        setGasCapacidadAlmacenamiento('');
        setNivelRiesgo('bajo');
        setTipoCliente('nuevo');
        setRazonSocial('');
        setRfc('');
        setDomicilioFiscal('');
        setListaCotizaciones([]);
        showTotal(0);
        setRequiereFactura(false)
        setConstanciaFiscal(null);
        setVigenciaVistoBueno(null);
        setVistoBuenoAniosAnteriores(false);
        setRepresentanteLegalInmueble('');
        setNombrePersonalRecibiraInspector('');
        setNivelesConstruccion('');
        setHorarioLaboral('');
        setDiasLaborales([
            {
                name: 'Domingo', enabled: false
            },
            {
                name: 'Lunes', enabled: true
            },
            {
                name: 'Martes', enabled: true
            },
            {
                name: 'Miércoles', enabled: true
            },
            {
                name: 'Jueves', enabled: true
            },
            {
                name: 'Viernes', enabled: true
            },
            {
                name: 'Sábado', enabled: false
            },
        ]);
    }

    const deleteCotizacionFromTemporalList = (index: number) => {

        const idFolioRemovido = listaCotizaciones[index].idFolio;
        const prefijoFolioRemovido = listaCotizaciones[index].prefijoFolio;

        const actual = secuencias.decrementSecuencia(idFolioRemovido);
        const base = secuencias.getBase(idFolioRemovido);
        let newCotizacionesList = listaCotizaciones.filter((item: Servicio, idx: number) => {
            return (idx !== index);
        })
        showTotal(getSumaTotalServicios(newCotizacionesList));
        let k = 0;
        setListaCotizaciones(newCotizacionesList.map((cotizacion: Servicio) => {
            if (cotizacion.idFolio === idFolioRemovido) {
                cotizacion.folio = `${FOLIO_PREFIX}-${cotizacion.prefijoFolio}-${cotizacion.claveFolio}-${formatoNumeroFolio(base + k)}`;
                k += 1;
            }
            return cotizacion;
        }));
        if (prefijoFolioRemovido === catalogoServicioSeleccionado?.prefijoFolio) {
            setSecuenciaFolioActual(actual);
        }
    }

    function isFormularioValido() {
        let newErrores = {};
        if (!claveFolio || claveFolio.trim().length === 0) {
            newErrores = {...newErrores, folio: "Especifique un número de claveFolio"};
        }
        if (!nombreContacto || nombreContacto.trim().length === 0) {
            newErrores = {...newErrores, nombreContacto: "Especifique un nombre de contacto"};
        }
        if (!telefonoContacto || telefonoContacto.trim().length === 0) {
            newErrores = {...newErrores, telefonoContacto: "Especifique un número de teléfono de contacto"};
        } else if (!telefonoContacto.match(/[0-9]{10}/g)) {
            newErrores = {...newErrores, telefonoContacto: "Especifique un número de teléfono de contacto válido"};
        }
        if (!claveFolio || claveFolio.trim().length === 0 || claveFolio.match(/[A-Z]{3}20[0-9]{2}\\-SPC-[A-Z]+/)) {
            newErrores = {...newErrores, folio: "Especifique un claveFolio válido"};
        }
        if (!correoContacto || correoContacto.trim().length === 0) {
            newErrores = {...newErrores, correoContacto: "Correo de contacto es requerido"};
        } else if (!isCorreoValido(correoContacto)) {
            newErrores = {...newErrores, correoContacto: "Especifique un correo de contacto válido"};
        }
        if (!nombreComercial || nombreComercial.trim().length === 0) {
            newErrores = {...newErrores, nombreComercial: "Nombre comercial es requerido"};
        }
        if (!direccionExacta || direccionExacta.trim().length === 0) {
            newErrores = {...newErrores, direccionExacta: "Dirección es requerida"};
        }
        if (!giroComercial || giroComercial.trim().length === 0) {
            newErrores = {...newErrores, giroComercial: "Giro comercial es requerido"};
        }
        if (!metrosConstruccion || metrosConstruccion.trim().length === 0) {
            newErrores = {...newErrores, metrosConstruccion: "Metros de construcción es requerido"};
        }
        if (!sustanciasQuimicosPeligrosos || sustanciasQuimicosPeligrosos.trim().length === 0) {
            newErrores = {...newErrores, sustanciasQuimicosPeligrosos: "Indique NA en caso de no aplicar"};
        }
        if (!gasCapacidadAlmacenamiento || gasCapacidadAlmacenamiento.trim().length === 0) {
            newErrores = {...newErrores, gasCapacidadAlmacenamiento: "Indique NA en caso de no aplicar"};
        }
        if (!razonSocial || razonSocial.trim().length === 0) {
            newErrores = {...newErrores, razonSocial: "La razón social es un valor requerido"};
        }
        setErrores(newErrores)
        return Object.keys(newErrores).length !== 0;
    }

    const addCotizacionToTemporalList = async () => {

        if (!isFormularioValido() && catalogoServicioSeleccionado) {
            if (documentoCotizacionFile === null) {
                showError('El documento de cotización es requerido');
            } else {
                const precio = catalogoServicioSeleccionado?.subservicios ? getSumaPrecioSubservicios(catalogoServicioSeleccionado.subservicios) : 0;
                const descuento = catalogoServicioSeleccionado?.subservicios ? getSumaDescuentoSubservicios(catalogoServicioSeleccionado.subservicios) : 0;
                const subtotal = catalogoServicioSeleccionado?.subservicios ? getSumaSubtotalSubservicios(catalogoServicioSeleccionado.subservicios) : 0;
                const iva = subtotal * (porcentajeIva / 100.0);
                // @ts-ignore
                let newService: Servicio = {
                    servicioProteccionCivil: servicioProteccionCivil,
                    clienteNuevo: tipoCliente === 'nuevo',
                    folioSecretariaEstatal: "",
                    observacionesDocumentacion: null,
                    fechaCapacitacion: null,
                    fechaEstimada: null,
                    fechaInspeccion: null,
                    grupoId: '',
                    observacionesAutoridad: false,
                    fechaAceptado: null,
                    fechaEnviado: null,
                    fechaExpirado: null,
                    fechaRechazado: null,
                    fechaRegistro: null,
                    tipo: '',
                    status: '',
                    coordinador: null,
                    operador: null,
                    calidad: null,
                    instructor: null,
                    requiereFactura: requiereFactura,
                    grupo: grupoSeleccionado,

                    folio: `${FOLIO_PREFIX}-${catalogoServicioSeleccionado.prefijoFolio.toUpperCase()}-${claveFolio.toUpperCase()}-${formatoNumeroFolio(secuenciaFolioActual)}`,
                    folioSecuencia: secuenciaFolioActual,
                    idFolio: `${claveFolio.toUpperCase()}${catalogoServicioSeleccionado.prefijoFolio.toUpperCase()}`,
                    claveFolio: `${claveFolio.toUpperCase()}`,

                    constanciaSituacionFiscal: requiereFactura ? constanciaFiscal : null,
                    cliente: {
                        nombreCompleto: nombreContacto,
                        correo: correoContacto,
                        telefono: telefonoContacto,
                        detalle: {
                            nombreComercial: nombreComercial,
                            giro: giroComercial,
                            metrosConstruccion: metrosConstruccion,
                            metrosTerreno: metrosTerreno,
                            numeroEmpleados: numeroEmpleados,
                            direccion: direccionExacta,
                            sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos,
                            gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento,
                            nivelRiesgo: nivelRiesgo,
                            razonSocial: razonSocial,
                            domicilioFiscal: domicilioFiscal,
                            rfc: rfc,
                        }
                    },
                    total: subtotal + iva, ...catalogoServicioSeleccionado
                }
                if (constanciaSituacionFiscal) {
                    newService.constanciaSituacionFiscalFile = new File([constanciaSituacionFiscal], constanciaSituacionFiscal.name, {
                        type: constanciaSituacionFiscal.type,
                        lastModified: constanciaSituacionFiscal.lastModified,
                    });
                }
                if (documentoCotizacionFile) {
                    newService.documentoCotizacionFile = new File([documentoCotizacionFile], documentoCotizacionFile.name, {
                        type: documentoCotizacionFile.type,
                        lastModified: documentoCotizacionFile.lastModified,
                    });
                }
                newService.precio = precio;
                newService.subtotal = subtotal;
                newService.iva = iva;
                newService.plantillaDocumentoCotizacion = catalogoServicioSeleccionado.plantillaDescargable;
                newService.documentoCotizacion = documentoCotizacion;
                newService.representanteLegalInmueble = representanteLegalInmueble;
                newService.nombrePersonalRecibeInspector = nombrePersonalRecibiraInspector;
                newService.horarioLaboral = horarioLaboral;
                newService.diasLaborales = diasLaborales;
                newService.nivelesConstruccion = nivelesConstruccion;
                newService.cuentaVistoBuenoPCAnteriores = vistoBuenoAniosAnteriores;
                if (vigenciaVistoBueno)
                    newService.vigenciaVistoBueno = dateToSimpleFormat(vigenciaVistoBueno);
                else newService.vigenciaVistoBueno = null;
                if (descuento) {
                    newService = {...newService, descuento: descuento};
                }
                const newCotizacionesList = [...listaCotizaciones, newService]
                showTotal(getSumaTotalServicios(newCotizacionesList));
                setListaCotizaciones(newCotizacionesList);
                setDocumentoCotizacion(null);


                const newSecuencia = secuencias.incrementSecuencia(`${claveFolio.toUpperCase()}${catalogoServicioSeleccionado.prefijoFolio.toUpperCase()}`);

                setSecuenciaFolioActual(newSecuencia);

            }
        } else {
            showError('Por favor verifique y proporcione los campos requeridos');
        }
    }

    const registrarCotizaciones = async () => {
        if (secuenciaFolioActual >= 0 && nombreComercial && catalogoPrefijoFolio) {
            if (listaCotizaciones.length > 0) {
                setLoading(true);
                setTextoButtonEnviar("Guardando...");
                try {
                    const resp = await guardarCotizaciones({
                        cotizaciones: listaCotizaciones
                    });
                    const cotRegs = resp.data;
                    for (let i = 0; i < cotRegs.length; i++) {
                        const ref = listaCotizaciones.filter(item => item.folio === cotRegs[i].folio);
                        if (ref[0] !== null) {
                            if (ref[0].constanciaSituacionFiscalFile) {
                                const formData = new FormData();
                                formData.append("file", ref[0].constanciaSituacionFiscalFile);
                                const json = JSON.stringify(cotRegs[i]);
                                const blob = new Blob([json], {
                                    type: 'application/json'
                                });
                                formData.append("cotizacion", blob);
                                const data = await actualizarConstanciaSituacionFiscal(cotRegs[i].id, formData);
                            }
                            if (ref[0].documentoCotizacionFile) {
                                const formData = new FormData();
                                formData.append("file", ref[0].documentoCotizacionFile);
                                const json = JSON.stringify(cotRegs[i]);
                                const blob = new Blob([json], {
                                    type: 'application/json'
                                });
                                formData.append("cotizacion", blob);
                                const data = await actualizarDocumentoCotizacion(cotRegs[i].id, formData);
                            }
                        }
                    }
                    limpiarFormulario();
                    if (resp.statusCode === OK) {
                        showSuccess(resp.message);
                    } else {
                        showError(resp.message);
                    }
                } catch (err) {
                    showError('Lo sentimos ha ocurrido un error al intentar guardar la cotización');
                } finally {
                    setLoading(false);
                    setTextoButtonEnviar("Enviar cotización");
                }
            }
        }
    }

    const showTotal = (total: number) => {
        setTotal(total)
    }

    const labelCurrency = (value: number | null | undefined) => {
        if (value) {
            return <>${(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</>;
        }
        return <>$0.00</>;
    }

    const [message, setMessage] = useState<string>('');
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const showSuccess = (message: string) => {
        setError(false);
        setMessage(message);
        setShowMessage(true);
    }

    const showError = (message: string) => {
        setError(true);
        setMessage(message);
        setShowMessage(true);
    }


    const downloadDocumentoCotizacion = async (catalogoServicio: CatalogoServicio) => {
        if (!isFormularioValido()) {
            // @ts-ignore
            let servicio: Servicio = {
                requiereFactura: requiereFactura,
                grupo: grupoSeleccionado,
                plantillaDocumentoCotizacion: catalogoServicio.plantillaDescargable,
                folio: `${FOLIO_PREFIX}-${catalogoServicio.prefijoFolio.toUpperCase()}-${claveFolio.toUpperCase()}-${formatoNumeroFolio(secuenciaFolioActual)}`,
                cliente: {
                    nombreCompleto: nombreContacto,
                    correo: correoContacto,
                    telefono: telefonoContacto,
                    detalle: {
                        nombreComercial: nombreComercial,
                        giro: giroComercial,
                        metrosConstruccion: metrosConstruccion,
                        metrosTerreno: metrosTerreno,
                        numeroEmpleados: numeroEmpleados,
                        direccion: direccionExacta,
                        sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos,
                        gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento,
                        nivelRiesgo: nivelRiesgo,
                        razonSocial: razonSocial,
                        domicilioFiscal: domicilioFiscal,
                        rfc: rfc,
                    }
                },
                total: catalogoServicio.iva + catalogoServicio.subtotal,
                ...catalogoServicioSeleccionado
            }
            fetch(`/api/catalogo/${catalogoServicio.id}/cotizacion/download`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': await getCsrfToken(),
                    },
                    body: JSON.stringify(servicio),
                }).then(response => {
                if (response.ok) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = `${catalogoServicio.plantillaDescargable?.nombre}`;
                        a.click();
                    });
                } else {
                    throw new Error('Ocurrió un error al intentar descargar el documento');
                }
            }).catch(err => alert(err));

        }
    }

    // @ts-ignore
    function CustomToggle({eventKey}) {
        const [open, setOpen] = useState<boolean>(true);
        const decoratedOnClick = useAccordionButton(eventKey, (e) => {
            setOpen(!open);
        });
        return (
            <span className={open ? 'bi-dash-square' : 'bi-plus-square'} onClick={decoratedOnClick}></span>
        );
    }

    const cambiarSeleccionCatalogoServicio = (e: CatalogoServicio) => {
        setCatalogoServicioSeleccionado(e);
        setCatalogoPrefijoFolio(e.prefijoFolio);
        resetSecuenciaFolio(e, claveFolio);
    }

    const resetSecuenciaFolio = (e: CatalogoServicio | null, claveFolio: string | null) => {
        if (e && claveFolio) {
            secuencias.getCurrentSecuencia(`${claveFolio.toUpperCase()}${e.prefijoFolio.toUpperCase()}`).then(
                value => {
                    setSecuenciaFolioActual(value);
                }
            )
        } else {
            return 1;
        }
    }

    const buscarCliente = async () => {
        setLoading(true);
        try {
            if (!correoContacto || correoContacto.trim().length === 0) {
                setErrores({
                    errores,
                    correoContacto: "Correo de contacto es requerido"
                });
            } else if (!isCorreoValido(correoContacto)) {
                setErrores({
                    errores,
                    correoContacto: "Especifique un correo de contacto válido"
                });
            } else {
                setErrores(omit(errores, 'correoContacto'));
                if (catalogoPrefijoFolio) {
                    try {
                        const response = await getClienteByEmail(correoContacto, catalogoPrefijoFolio);
                        if (response.statusCode === OK) {
                            const cliente = response.data;
                            setNombreContacto(cliente.nombreCompleto);
                            setTelefonoContacto(cliente.telefono);
                            setNombreComercial(cliente.detalle.nombreComercial);
                            setGiroComercial(cliente.detalle.giro);
                            setDireccionExacta(cliente.detalle.direccion);
                            setMetrosTerreno(cliente.detalle.metrosTerreno);
                            setMetrosConstruccion(cliente.detalle.metrosConstruccion);
                            setNumeroEmpleados(cliente.detalle.numeroEmpleados);
                            setSustanciasQuimicosPeligrosos(cliente.detalle.sustanciasQuimicasPeligrosas);
                            setGasCapacidadAlmacenamiento(cliente.detalle.gasCapacidadAlmacenamiento);
                            setRazonSocial(cliente.detalle.razonSocial);
                            setRfc(cliente.detalle.rfc);
                            setDomicilioFiscal(cliente.detalle.domicilioFiscal);
                            setNivelRiesgo(cliente.detalle.nivelRiesgo);
                            setTipoCliente('renovacion');
                            setRequiereFactura(cliente.requiereFactura);
                            setRepresentanteLegalInmueble(cliente.representanteLegalInmueble);
                            setNombrePersonalRecibiraInspector(cliente.nombrePersonalRecibeInspector);
                            setHorarioLaboral(cliente.horarioLaboral);
                            if (cliente.diasLaborales)
                                setDiasLaborales(cliente.diasLaborales);
                            setNivelesConstruccion(cliente.nivelesConstruccion);
                            setVistoBuenoAniosAnteriores(cliente.cuentaVistoBuenoPCAnteriores);
                            if (cliente.vigenciaVistoBueno)
                                setVigenciaVistoBueno(toDate(cliente.vigenciaVistoBueno));
                            if (constanciaFiscal == null && cliente.constanciaSituacionFiscal) {
                                setRequiereFactura(true);
                                setConstanciaFiscal(cliente.constanciaSituacionFiscal);
                            }
                            const claveFolio = cliente.claveFolio ? cliente.claveFolio : truncarTexto(nombreComercial.toUpperCase().replace(/[\s|\.|,|_|-]/g, ''), 20, false);
                            setClaveFolio(claveFolio);
                            resetSecuenciaFolio(catalogoServicioSeleccionado, claveFolio);
                            if (isAdmin()) {
                                setGrupoSeleccionado(cliente.grupo);
                            }
                        }
                    } catch (err) {

                    }
                }
            }
        } finally {
            setLoading(false);
        }

    }

    // @ts-ignore
    return (
        <>

            <LoadingSpinner show={loading}/>
            <Container style={{background: '#FAFAFA'}}>
                <PageHeader label="Nueva cotización"/>

                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header className="list-header">
                            <CustomToggle eventKey="0"></CustomToggle>
                            <label className='fs-5 fw-bold' style={{marginLeft: "10px"}}>
                                Información general de la cotización
                            </label>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Container>
                                    <Form className="row g-6" id="formGeneral">
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Correo del Contacto</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control disabled={listaCotizaciones.length > 0}
                                                          type="email" maxLength={100} required
                                                          value={correoContacto}
                                                          isInvalid={!!errores.correoContacto}
                                                          onChange={(e) => setCorreoContacto(e.target.value)}
                                                          onBlur={(e) => buscarCliente()}
                                            ></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.correoContacto}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Nombre del Contacto</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control disabled={listaCotizaciones.length > 0}
                                                          type="text" max={200} required
                                                          value={nombreContacto}
                                                          isInvalid={!!errores.nombreContacto}
                                                          onChange={(e) => setNombreContacto(e.target.value)}
                                                          onBlur={() => {
                                                              if (!nombreContacto || nombreContacto.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      nombreContacto: "Especifique un nombre de contacto"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'nombreContacto'))
                                                              }
                                                          }}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.nombreContacto}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Teléfono del Contacto</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control disabled={listaCotizaciones.length > 0}
                                                          type="text" maxLength={10} required
                                                          value={telefonoContacto}
                                                          isInvalid={!!errores.telefonoContacto}
                                                          onChange={(e) => setTelefonoContacto(e.target.value)}
                                                          onBlur={() => {
                                                              if (!telefonoContacto || telefonoContacto.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      telefonoContacto: "Especifique un número de teléfono de contacto"
                                                                  });
                                                              } else if (!telefonoContacto.match(/[0-9]{10}/g)) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      telefonoContacto: "Especifique un número de teléfono de contacto válido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'telefonoContacto'))
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.telefonoContacto}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Nombre Comercial</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" className="formFont"
                                                          disabled={listaCotizaciones.length > 0} maxLength={200}
                                                          value={nombreComercial}
                                                          isInvalid={!!errores.nombreComercial}
                                                          onChange={(e) => setNombreComercial(e.target.value)}
                                                          onBlur={() => {
                                                              if (!nombreComercial || nombreComercial.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      nombreComercial: "Nombre comercial es requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'nombreComercial'))
                                                                  const newClaveFolio = claveFolio ? claveFolio : truncarTexto(nombreComercial.toUpperCase().replace(/[\s|\.|,|_|-]/g, ''), 20, false);
                                                                  if (!claveFolio) {
                                                                      setClaveFolio(newClaveFolio);
                                                                  }
                                                                  resetSecuenciaFolio(catalogoServicioSeleccionado, newClaveFolio);
                                                              }
                                                          }}/>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.nombreComercial}</Form.Control.Feedback>

                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Folio</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <div>
                                                <Row>
                                                    <Col sm='4'>
                                                        <Form.Control type='text' disabled={true}
                                                                      value={`${FOLIO_PREFIX}-${catalogoPrefijoFolio ? catalogoPrefijoFolio : 'XXX'}`}/>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text"
                                                                      disabled={listaCotizaciones.length > 0}
                                                                      max={200}
                                                                      required value={claveFolio}
                                                                      isInvalid={!!errores.folio}
                                                                      onChange={(e) => {
                                                                          setClaveFolio(e.target.value)
                                                                      }}
                                                                      onBlur={() => {
                                                                          if (!claveFolio || claveFolio.trim().length === 0 || !claveFolio.match(/[A-Z]+/)) {
                                                                              setErrores({
                                                                                  ...errores,
                                                                                  folio: "Especifique una clave de folio válida"
                                                                              });
                                                                          } else {
                                                                              resetSecuenciaFolio(catalogoServicioSeleccionado, claveFolio);
                                                                              setErrores(omit(errores, 'folio'))
                                                                          }
                                                                      }}
                                                        />
                                                    </Col>
                                                    <Col sm='4'>
                                                        <Form.Control type='text' disabled={true}
                                                                      value={formatoNumeroFolio(secuenciaFolioActual)}/>
                                                        {
                                                            secuenciaFolioActual > 0 && <span className='text-muted'
                                                                                              style={{fontSize: '0.5rem'}}><span
                                                                className='bi bi-info-circle'/> Folio asignado automáticamente</span>
                                                        }
                                                    </Col>
                                                </Row>


                                            </div>

                                            <Form.Control.Feedback
                                                type="invalid">{errores.folio}</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Dirección Exacta del Inmueble</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" maxLength={400}
                                                          value={direccionExacta}
                                                          onChange={(e) => {
                                                              setDireccionExacta(e.target.value)
                                                          }}
                                                          isInvalid={!!errores.direccionExacta}
                                                          onBlur={() => {
                                                              if (!direccionExacta || direccionExacta.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      direccionExacta: "La dirección exacta es requerida"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'direccionExacta'));
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.direccionExacta}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Giro Comercial</Form.Label>
                                            <Form.Control type="text" maxLength={400}
                                                          value={giroComercial} isInvalid={!!errores.giroComercial}
                                                          onChange={(e) => setGiroComercial(e.target.value)}
                                                          onBlur={() => {
                                                              if (!giroComercial || giroComercial.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      giroComercial: "El campo Giro Comercial es requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'giroComercial'));
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.giroComercial}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Metros cuadrados de Construcción</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" maxLength={100}
                                                          isInvalid={!!errores.metrosConstruccion}
                                                          value={metrosConstruccion}
                                                          onChange={(e) => setMetrosConstruccion(e.target.value)}
                                                          onBlur={() => {
                                                              if (!metrosConstruccion || metrosConstruccion.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      metrosConstruccion: "El campo metros de construcción es requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'metrosConstruccion'));
                                                              }
                                                          }}

                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.metrosConstruccion}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Metros cuadrados de Terreno</Form.Label>
                                            <Form.Control type="text" maxLength={100}
                                                          isInvalid={!!errores.metrosTerreno}
                                                          value={metrosTerreno}
                                                          onChange={(e) => setMetrosTerreno(e.target.value)}></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.metrosTerreno}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>No. de empleados</Form.Label>
                                            <Form.Control type="text" maxLength={100}
                                                          isInvalid={!!errores.numeroEmpleados}
                                                          value={numeroEmpleados}
                                                          onChange={(e) => setNumeroEmpleados(e.target.value)}></Form.Control>
                                            <Form.Control.Feedback
                                                type="invalid">{errores.numeroEmpleados}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Sustancias o Químicos Peligrosos</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" maxLength={100}
                                                          isInvalid={!!errores.sustanciasQuimicosPeligrosos}
                                                          value={sustanciasQuimicosPeligrosos}
                                                          onChange={(e) => setSustanciasQuimicosPeligrosos(e.target.value)}
                                                          onBlur={() => {
                                                              if (!sustanciasQuimicosPeligrosos || sustanciasQuimicosPeligrosos.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      sustanciasQuimicosPeligrosos: "Indique NA en caso de no aplicar"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'sustanciasQuimicosPeligrosos'))
                                                              }
                                                          }}

                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.sustanciasQuimicosPeligrosos}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Gas, capacidad de almacenamiento</Form.Label>
                                            <Form.Text className="text-muted"> (Requerido)</Form.Text>
                                            <Form.Control type="text" maxLength={100}
                                                          isInvalid={!!errores.gasCapacidadAlmacenamiento}
                                                          value={gasCapacidadAlmacenamiento}
                                                          onChange={(e) => setGasCapacidadAlmacenamiento(e.target.value)}
                                                          onBlur={() => {
                                                              if (!gasCapacidadAlmacenamiento || gasCapacidadAlmacenamiento.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      gasCapacidadAlmacenamiento: "Indique NA en caso de no aplicar"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'gasCapacidadAlmacenamiento'))
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.gasCapacidadAlmacenamiento}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Razón Social</Form.Label>
                                            <Form.Text className="text-muted"> (Se requiere el nombre de la empresa
                                                ante
                                                el
                                                SAT)</Form.Text>
                                            <Form.Control type="text" maxLength={100}
                                                          isInvalid={!!errores.razonSocial}
                                                          value={razonSocial}
                                                          onChange={(e) => setRazonSocial(e.target.value)}
                                                          onBlur={() => {
                                                              if (!razonSocial || razonSocial.trim().length === 0) {
                                                                  setErrores({
                                                                      ...errores,
                                                                      razonSocial: "La razón social es un valor requerido"
                                                                  });
                                                              } else {
                                                                  setErrores(omit(errores, 'razonSocial'))
                                                              }
                                                          }}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{errores.razonSocial}</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>Domicilio Fiscal</Form.Label>
                                            <Form.Control type="text" maxLength={400}
                                                          value={domicilioFiscal}
                                                          onChange={(e) => setDomicilioFiscal(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral"
                                                    style={{marginBottom: "20px"}}>
                                            <Form.Label>RFC (Registro Federal de Contribuyente)</Form.Label>
                                            <Form.Control type="text" maxLength={30}
                                                          value={rfc}
                                                          onChange={(e) => setRfc(e.target.value)}></Form.Control>
                                        </Form.Group>
                                        <Form.Group></Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral">
                                            <Form.Label>Nivel de riesgo</Form.Label>
                                            <div className="mb-3">
                                                <Form.Check
                                                    checked={nivelRiesgo === 'bajo'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNivelRiesgo('bajo')
                                                        }
                                                    }} inline label="Bajo" type='radio'
                                                />
                                                <Form.Check
                                                    checked={nivelRiesgo === 'medio'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNivelRiesgo('medio')
                                                        }
                                                    }} inline label="Medio" type='radio'
                                                />
                                                <Form.Check
                                                    checked={nivelRiesgo === 'alto'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setNivelRiesgo('alto')
                                                        }
                                                    }} inline label="Alto" type='radio'
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="col-md-6" controlId="formGeneral">
                                            <Form.Label>Tipo de cliente</Form.Label>
                                            <div className="mb-3">
                                                <Form.Check
                                                    checked={tipoCliente === 'nuevo'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setTipoCliente('nuevo')
                                                        }
                                                    }} inline label="Nuevo" type='radio'
                                                />
                                                <Form.Check
                                                    checked={tipoCliente !== 'nuevo'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setTipoCliente('renovacion')
                                                        }
                                                    }} inline label="Renovación" type='radio'
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form className="row g-6" id="formDatosGeneralesOperativos">
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Representante Legal del Inmueble</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={representanteLegalInmueble}
                                                              onChange={(e) => setRepresentanteLegalInmueble(e.target.value)}
                                                              maxLength={100}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Nombre del personal que recibirá al
                                                    inspector</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={nombrePersonalRecibiraInspector}
                                                              onChange={(e) => setNombrePersonalRecibiraInspector(e.target.value)}
                                                              maxLength={100}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Niveles de construcción</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={nivelesConstruccion}
                                                              onChange={(e) => setNivelesConstruccion(e.target.value)}
                                                              maxLength={50}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Horario laboral</Form.Label>
                                                <Form.Control type="text" className="formFont"
                                                              value={horarioLaboral}
                                                              onChange={(e) => setHorarioLaboral(e.target.value)}
                                                              maxLength={50}></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>Días laborales</Form.Label>
                                                <div>
                                                    <DiasSemana workingDays={diasLaborales} disabled={false}
                                                                change={changeSelectionDay}/>
                                                </div>
                                            </Form.Group>

                                            <Form.Group className="col-md-6" controlId="formDatosGenerales"
                                                        style={{marginBottom: "20px"}}>
                                                <Form.Label>¿Cuentan con visto bueno de protección civil de años
                                                    anteriores?</Form.Label>
                                                <div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox"
                                                               id='requiereFactura'
                                                               checked={vistoBuenoAniosAnteriores}
                                                               onChange={(e) => setVistoBuenoAniosAnteriores(e.target.checked)}/>
                                                        <label className="form-check-label"
                                                               htmlFor="requiereFactura">
                                                            Sí
                                                        </label>
                                                    </div>
                                                    {
                                                        vistoBuenoAniosAnteriores &&
                                                        <div>
                                                            Vigencia:
                                                            <DatePicker isClearable locale="es"
                                                                        dateFormat='dd/MM/yyyy'
                                                                        selected={vigenciaVistoBueno}
                                                                        onChange={(date, event) => {
                                                                            setVigenciaVistoBueno(date);
                                                                        }}/>
                                                        </div>
                                                    }
                                                </div>

                                            </Form.Group>
                                        </Form>
                                    </Form>
                                    <div style={{marginTop: "10px"}}>
                                        <div className="form-check">
                                            <label className="form-check-label" htmlFor="requiereFactura">
                                                ¿Requiere factura?
                                            </label>
                                            <input className="form-check-input" type="checkbox"
                                                   id='requiereFactura'
                                                   checked={requiereFactura}
                                                   onChange={(e) => setRequiereFactura(e.target.checked)}/>
                                            {
                                                requiereFactura &&
                                                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                                                    <FileSelector text={'Agregar constancia de situación fiscal'}
                                                                  onSelect={(file: any) => setConstanciaSituacionFiscal(file)}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <hr/>
                                    <div>
                                        <label className='fs-5 fw-bold'>Tipo de servicio</label>
                                    </div>
                                    <Form.Group className="col-md-6" controlId="formGeneral">
                                        <div className="mb-3">
                                            <Form.Check
                                                checked={servicioProteccionCivil}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setServicioProteccionCivil(e.target.checked)
                                                    }
                                                }} inline label="Servicios de protección civil" type='radio'
                                            />
                                            <Form.Check
                                                checked={!servicioProteccionCivil}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setServicioProteccionCivil(!e.target.checked)
                                                    }
                                                }} inline label="Otros servicios" type='radio'
                                            />
                                        </div>
                                    </Form.Group>

                                    {
                                        isAdmin() &&
                                        <>
                                            <div>
                                                <label className='fs-5 fw-bold'>Grupo de atención</label>
                                            </div>
                                            <div className="mb-3" style={{marginTop: '10px'}}>
                                                {
                                                    grupos?.map((g: Grupo) => {
                                                        return <Form.Check
                                                            checked={grupoSeleccionado?.id === g.id}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setGrupoSeleccionado(g);
                                                                }
                                                            }} inline label={g.nombre} type='radio'
                                                        />
                                                    })
                                                }
                                            </div>
                                        </>
                                    }


                                    <div className='d-flex justify-content-center'>
                                        <label className='fs-3 fw-bold'>Servicios</label>
                                    </div>
                                    <Container className="d-flex flex-row justify-content-center"
                                               style={{marginTop: "30px", marginBottom: '30px'}}>
                                        {
                                            isLoadingCatalogo ?
                                                <RotatingLines
                                                    strokeColor="grey"
                                                    visible={true}
                                                    width="50"
                                                    strokeWidth="5"
                                                    animationDuration="0.75"
                                                    ariaLabel="rotating-lines-loading"
                                                /> : <>


                                                    {
                                                        listaCatalogoServicio === null && <>
                                                            <span className="fs-4 fw-bold">Lo sentimos ha ocurrido un error al cargar el catálogo de servicios...</span>
                                                        </>
                                                    }
                                                    {
                                                        indicePaginaCatalogoServicio > 2 &&
                                                        <Button variant='' style={{fontSize: '1.5rem'}} onClick={() => {
                                                            const i = indicePaginaCatalogoServicio - 1;
                                                            setIndicePaginaCatalogoServicio(i);
                                                            const copia = listaCatalogoServicio.filter((item: CatalogoServicio, index: number) => {
                                                                if (index >= (i - 2) && index <= i) return item;
                                                            })
                                                            setCatalogoServicioPaginado(copia);
                                                        }}><i className="bi bi-chevron-double-left"></i>
                                                        </Button>
                                                    }
                                                    {
                                                        catalogoServicioPaginado.map((catalogoServicio: CatalogoServicio) => {
                                                            return (
                                                                <CatalogoServicioPanel catalogoServicio={catalogoServicio}
                                                                                       seleccionado={(catalogoServicio.id === catalogoServicioSeleccionado?.id)}
                                                                                       seleccionar={(e: CatalogoServicio) => {
                                                                                           setDocumentoCotizacion(null);
                                                                                           cambiarSeleccionCatalogoServicio(e);
                                                                                       }}
                                                                                       editar={(e: CatalogoServicio) => {
                                                                                           const copy = cloneDeep(e);
                                                                                           setCatalogoServicioEditable(copy);
                                                                                           setShowCatalogoServicioPanel(true);
                                                                                       }}
                                                                                       downloadPreview={() => downloadDocumentoCotizacion(catalogoServicio)}

                                                                />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        indicePaginaCatalogoServicio < (listaCatalogoServicio.length - 1) &&
                                                        <Button variant='' style={{fontSize: '1.5rem'}} onClick={() => {
                                                            const i = indicePaginaCatalogoServicio + 1;
                                                            setIndicePaginaCatalogoServicio(i);
                                                            const copia = listaCatalogoServicio.filter((item: CatalogoServicio, index: number) => {
                                                                if (index >= (i - 2) && index <= i) return item;

                                                            })
                                                            setCatalogoServicioPaginado(copia);
                                                        }}>
                                                            <i className="bi bi-chevron-double-right"></i>
                                                        </Button>
                                                    }
                                                </>

                                        }
                                    </Container>

                                    <FileSelector text={'Agregar documento de cotización'}
                                                  onSelect={(file: any) => {
                                                      setDocumentoCotizacionFile(file)
                                                  }}/>
                                </Container>
                                {
                                    listaCatalogoServicio.length > 0 &&
                                    <Container className="d-flex flex-row justify-content-center"
                                               style={{marginTop: "30px"}}>
                                        <Button style={{padding: "15px"}}
                                                variant="dark" size='sm'
                                                onClick={addCotizacionToTemporalList}>
                                            <span className='fw-bold fs-6'>
                                                Añadir a la lista de cotizaciones
                                            </span>

                                        </Button>
                                    </Container>
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
                <Container style={{
                    marginTop: "20px", paddingTop: "20px", paddingBottom: "20px",
                    backgroundColor: 'rgba(254,254,254,0.98)', color: "black", border: "1px solid lightgray",
                    borderRadius: "8px"
                }}>

                    <Table bordered hover className="table">
                        <thead>
                        <tr>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Folio</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Nombre comercial</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Precio</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Descuento</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Subtotal</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>I.V.A</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Total</th>
                            <th style={{height: '50px', backgroundColor: '#3a3939'}}>Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            listaCotizaciones.map((cot: Servicio, index: any) => {
                                return (
                                    <tr>
                                        <td><span
                                            className='fw-bold'>{truncarTexto(cot.folio, 40)}</span>
                                        </td>
                                        <td><span
                                            className='fw-bold'>{truncarTexto(cot.cliente.detalle.nombreComercial, 30)}</span>
                                        </td>
                                        <td><span className='fw-bold'>{labelCurrency(cot.precio)}</span>
                                        </td>
                                        <td><span
                                            className='fw-bold'>{cot.descuento ? labelCurrency(cot.descuento) : 'N/A'}</span>
                                        </td>
                                        <td><span
                                            className='fw-bold'>{labelCurrency(cot.subtotal)}</span>
                                        </td>
                                        <td><span className='fw-bold'>{labelCurrency(cot.iva)}</span>
                                        </td>
                                        <td><span className='fw-bold'>{labelCurrency(cot.total)}</span>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center'>
                                                <DeleteButton onClick={() => {
                                                    deleteCotizacionFromTemporalList(index);
                                                }}/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>

                    <Row>
                        <Col className="col-sm-7">
                        </Col>
                        <Col>
                            <TotalLabel total={total} withIva={true}/>
                        </Col>
                    </Row>
                    {
                        listaCotizaciones.length > 0 &&
                        <Container className="d-flex flex-row justify-content-center">
                            <Button style={{padding: "10px", width: "200px"}} variant="dark" size='sm'
                                    disabled={loading}
                                    onClick={registrarCotizaciones}>
                                 <span className='fw-bold fs-6'>
                                {textoButtonEnviar}
                                 </span>
                            </Button>
                        </Container>
                    }
                </Container>
                <br/>
            </Container>

            {
                catalogoServicioEditable &&
                <CatalogoServicioModal show={showCatalogoServicioPanel}
                                       downloadDocCotizacionPrueba={(tempCatalogoServicio: CatalogoServicio) => {
                                           if (!isFormularioValido() && tempCatalogoServicio) {

                                               // @ts-ignore
                                               let servicioPrueba: Servicio = {
                                                   requiereFactura: requiereFactura,
                                                   grupo: grupoSeleccionado,
                                                   plantillaDocumentoCotizacion: tempCatalogoServicio.plantillaDescargable,
                                                   folio: claveFolio,
                                                   cliente: {
                                                       nombreCompleto: nombreContacto,
                                                       correo: correoContacto,
                                                       telefono: telefonoContacto,
                                                       detalle: {
                                                           nombreComercial: nombreComercial,
                                                           giro: giroComercial,
                                                           metrosConstruccion: metrosConstruccion,
                                                           metrosTerreno: metrosTerreno,
                                                           numeroEmpleados: numeroEmpleados,
                                                           direccion: direccionExacta,
                                                           sustanciasQuimicasPeligrosas: sustanciasQuimicosPeligrosos,
                                                           gasCapacidadAlmacenamiento: gasCapacidadAlmacenamiento,
                                                           nivelRiesgo: nivelRiesgo,
                                                           razonSocial: razonSocial,
                                                           domicilioFiscal: domicilioFiscal,
                                                           rfc: rfc,
                                                       }
                                                   },
                                                   total: tempCatalogoServicio.iva + tempCatalogoServicio.subtotal,
                                                   ...catalogoServicioSeleccionado
                                               }
                                               registrarServicioPrueba(servicioPrueba).then(resp => {
                                                   if (resp.statusCode === OK) {
                                                       fetch(`api/catalogo/cotizacion/prueba/${resp.data.id}`)
                                                           .then(response => {
                                                               if (tempCatalogoServicio.plantillaDescargable) {
                                                                   response.blob().then(blob => {
                                                                       let url = window.URL.createObjectURL(blob);
                                                                       let a = document.createElement('a');
                                                                       a.href = url;
                                                                       a.download = `${tempCatalogoServicio.plantillaDescargable?.nombre}`;
                                                                       a.click();
                                                                   });
                                                               }
                                                           });
                                                   }

                                               }).catch(err => alert(err))

                                           }
                                       }}
                                       cambiarPlantilla={uploadTmpFile}
                                       catalogoServicio={catalogoServicioEditable}
                                       close={() => {
                                           setShowCatalogoServicioPanel(false)
                                       }}
                                       guardarCambios={(e: CatalogoServicio) => {
                                           const newCatalogoServicioPaginado = catalogoServicioPaginado.map((item: CatalogoServicio) => {
                                               if (item.id === e.id) return e;
                                               else return item;
                                           });
                                           setCatalogoServicioPaginado(newCatalogoServicioPaginado);
                                           cambiarSeleccionCatalogoServicio(e);
                                           setShowCatalogoServicioPanel(false);
                                       }}/>

            }
            <ModalMessage show={showMessage} close={() => setShowMessage(false)} error={error} message={message}/>

        </>
    )
}