import React from "react";

export default function SubmenuItem(props: { isOpen:boolean,onSelect:()=>void,selected:boolean,icon:string,text:string }) {
    return (
        <a href='#'
           className={`menu-item rounded list-group-item list-group-item-action ${props.selected ? 'active' : 'bg-dark'} text-white justify-content-between`}
           style={{border: '0px', paddingTop: '13px'}} onClick={props.onSelect}>
            <span className={props.icon}/> {props.isOpen&&<span className="menu-collapsed">{props.text}</span>}
        </a>
    )
}