import {Button, Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {formatoFechaTexto, isAdmin, isCliente, isVentas, truncarTexto} from "../../utils";
import {Documento, Servicio} from "../../types/servicio";
import ActividadList from "../actividad/ActividadList";
import Advertencia from "../mensajes/Advertencia";
import DocumentoItem from "../documento/DocumentoItem";
import React from "react";
import FormGeneralReadOnly from "./FormGeneralReadOnly";
import {useNavigate} from "react-router-dom";
import LabelFechaEstimada from "../common/LabelFechaEstimada";
import LabelNombreOperativo from "../common/LabelNombreOperativo";


export default function PanelServicio(props: { servicio: Servicio, avance: number }) {

    const navigate = useNavigate();


    const LabelStatusServicio = (status: string) => {
        let estilo = "warning";
        let textStatus = "Próximo a iniciar";
        if (status === 'en_progreso') {
            estilo = "done";
            textStatus = 'En progreso';
        }
        if (status === 'finalizado') {
            estilo = "success";
            textStatus = 'Finalizado';
        }
        return <div className={`conem-label conem-bg-${estilo} rounded`} style={{color: '#3a3939'}}>
            Status del servicio: <span className="fw-bold" style={{fontSize: "0.9em"}}>{textStatus}</span>
        </div>
    }


    const LabelStatusPago = (comprobantesPagos: Documento | null | undefined) => {
        let estilo = "danger";
        let status = 'Pendiente de orden de compra';
        if (comprobantesPagos) {
            if (comprobantesPagos.verificado) {
                estilo = "success";
                status = "Liquidado";
            } else if (comprobantesPagos.archivos && comprobantesPagos.archivos.length > 0) {
                estilo = "warning";
                status = "Pendiente de Liquidar"
            }
        }
        return <div className={`conem-label conem-bg-${estilo} rounded`} style={{color: '#3a3939'}}>
            <label>Status de pago: <span className="fw-bold" style={{fontSize: "0.9em"}}>{status}</span></label>
        </div>
    }

    const LabelEncuestaSatisfaccion = (servicio: Servicio) => {
        if (servicio.status === 'finalizado') {
            if (servicio.encuestaSatisfaccion) {
                return <div style={{marginLeft: '10px'}}><span className='bi bi-info-circle-fill'
                                                               style={{color: 'rgb(6, 150, 215)'}}></span><span
                    className='folderName' onClick={() => {


                }}>Ver resultados de la encuesta de satisfacción</span>
                </div>
            } else {
                return <div style={{marginLeft: '10px'}}><span className='bi bi-info-circle-fill'
                                                               style={{color: 'red'}}></span>
                    <span className='folderName' onClick={() => {

                    }}>Realizar la encuesta de satisfacción</span>
                </div>
            }
        }
        return <></>
    }


    return (
        <Container style={{marginTop: '20px'}}>
            <Card>
                <Card.Header className="servicio-list-item-header">
                    <Row>
                        <Col className="col-4">
                            <Row>
                                <Col>
                                    <div style={{marginBottom: '5px'}}>
                                        Folio: <span className="fw-bold" style={{fontSize: "14px"}}>
                                    {props.servicio.folio}
                                    </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        LabelStatusServicio(props.servicio.status)
                                    }
                                    {
                                        (isAdmin() || isVentas() || isCliente()) && LabelStatusPago(props.servicio.documentos[0])
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        (isAdmin() || isVentas() || isCliente()) && LabelEncuestaSatisfaccion(props.servicio)
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col>

                            <div>Calidad:</div>
                            <div>
                                <span className='fw-bold'>
                                    {props.servicio.calidad ? truncarTexto(props.servicio.calidad?.nombreCompleto, 30) : 'No disponible'}
                                </span>
                            </div>

                            <div>Coordinador:</div>
                            <div><span
                                className='fw-bold'>{props.servicio.coordinador ? truncarTexto(props.servicio.coordinador.nombreCompleto, 30) : 'No disponible'}</span>
                            </div>
                        </Col>
                        <Col sm='2'>
                            <div className={`c100 p${props.avance} center`}>
                                <span>{props.avance}%</span>
                                <div className="slice">
                                    <div className="bar"></div>
                                    <div className="fill"></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <Row>
                            <Col sm='4'>
                                <div className='d-inline-flex'>
                                    <label style={{marginRight: '10px'}}>Fecha de inspección:</label>
                                    {
                                        props.servicio.fechaInspeccion ?
                                            <span
                                                className='fw-bold'>{formatoFechaTexto(props.servicio.fechaInspeccion)}</span> :
                                            <span className='fw-bold'>No disponible</span>
                                    }
                                </div>
                            </Col>
                            <Col sm='4'>
                                <div className='d-inline-flex'>
                                    <label style={{marginRight: '10px'}}>Fecha de capacitación:</label>
                                    {
                                        props.servicio.fechaCapacitacion ?
                                            <span
                                                className='fw-bold'>{formatoFechaTexto(props.servicio.fechaCapacitacion)}</span> :
                                            <span className='fw-bold'>No disponible</span>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-center fs-2"
                                     style={{marginTop: '30px', marginBottom: '30px', color: '#3a3939'}}>
                                    {
                                        props.servicio.status === 'finalizado' && <>
                                                <span>Este servicio finalizó el <span
                                                    className='fw-bold'>{formatoFechaTexto(props.servicio.fechaFinalizado)}</span></span>
                                        </>
                                    }
                                    <LabelFechaEstimada servicio={props.servicio}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <div className='d-flex justify-content-center'>
                        <Button variant='outline-primary' onClick={() => {
                            navigate('/');
                        }} style={{width: '200px'}}>Ir a inicio sesión</Button>
                    </div>


                    <Tabs defaultActiveKey='generales' id='serviceDetailsTab'
                          className='mb-3'>
                        <Tab eventKey='generales' className='fs-6' title='Información general'>
                            <div style={{marginBottom: '20px'}}>
                                <Row>
                                    <Col>
                                        <LabelNombreOperativo servicio={props.servicio}/>
                                    </Col>
                                    <Col>
                                        {
                                            <>
                                                <div>Instructor:</div>
                                                <div style={{marginTop: '5px'}}>
                                        <span className='fw-bold'>{props.servicio.instructor ? truncarTexto(props.servicio.instructor.nombreCompleto, 100) : 'No disponible'}</span>
                                                </div>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <hr/>
                            {
                                <FormGeneralReadOnly servicio={props.servicio}/>
                            }


                        </Tab>
                        <Tab eventKey='actividades' title='Actividades'>
                            <Container className='rounded' style={{paddingTop: '10px'}}>
                                {
                                    <ActividadList servicioId={props.servicio.id} folioSecretariaEstatal={props.servicio.folioSecretariaEstatal}/>
                                }
                            </Container>

                        </Tab>
                        <Tab eventKey='documentos' className='fs-6' title='Documentos' style={{
                            padding: '20px'
                        }}>
                            {
                                props.servicio.observacionesDocumentacion && <>
                                    <Advertencia showIcon={true} mensaje={props.servicio.observacionesDocumentacion}/>
                                    <hr/>
                                </>
                            }
                            {
                                props.servicio.documentos?.map((documento: Documento) => {
                                    return <DocumentoItem documento={documento} servicio={props.servicio}/>
                                })
                            }
                        </Tab>

                    </Tabs>

                </Card.Body>
            </Card>
        </Container>

    )
}