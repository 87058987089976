import React from "react";
import {RotatingLines} from "react-loader-spinner";

export default function LoadingSpinner(props: { show: boolean }) {
    return (
        <>
            {
                props.show &&
                <div id="backdrop">
                    <div>
                        <RotatingLines
                            strokeColor="grey"
                            visible={true}
                            width="50"
                            strokeWidth="5"
                            animationDuration="0.75"
                            ariaLabel="rotating-lines-loading"
                        />
                        <div className='fw-bold d-flex' style={{color:'#4a4848',marginLeft:'-12px'}}>
                            Cargando...
                        </div>
                    </div>
                </div>
            }
        </>

    )
}