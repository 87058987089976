import axios from "axios";
import {getCsrfToken} from "./conem-services";
import {SecuenciaCliente} from "../types/secuencia-cliente.type";
import {Response} from "../types/response.type";
import {RegistroCotizacion} from "../types/cotizacion.types";

/*
* Devuelve el folio asignado automaticamente para el cliente especificado
* clienteId: identificador del cliente para solicitar un nuevo folio
* */
export async function getFolio(idFolio:string):Promise<Response<SecuenciaCliente>> {
    const {data} = await axios.get(`api/servicios/clientes/folio/${idFolio}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}

export async function guardarCotizaciones(registroCotizacion: RegistroCotizacion) {
    const {data} = await axios.post('/api/cotizaciones', registroCotizacion, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}