import {Button, Spinner} from "react-bootstrap";
import React, {useState} from "react";


export default function DownloadButton(props: {
    url: string,
    fileName: string,
    text: string,
    textDownloading: string,
    icon?: string,
    params?: { key: string, value: any }[]
}) {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    return (
        <Button size='sm' variant='outline-dark'
                disabled={isDownloading}
                onClick={() => {
                    setIsDownloading(true);
                    const initialValue = '?';
                    fetch(props.url + props.params?.reduce((accumulator, currentValue) => accumulator + `${currentValue.value?(currentValue.key+"="+currentValue.value+"&"):""}`, initialValue))
                        .then(response => {
                            if (response.ok){
                                response.blob().then(blob => {
                                    let url = window.URL.createObjectURL(blob);
                                    let a = document.createElement('a');
                                    a.href = url;
                                    a.download = props.fileName;
                                    a.click();
                                });
                            }else{
                                throw new Error("Ocurrió un error al intentar descargar el archivo");
                            }
                        }).catch(error=>alert(error))
                        .finally(() => {
                        setIsDownloading(false);
                    });
                }}>
            {
                isDownloading ?
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span>{props.textDownloading}</span>
                    </> :
                    <><i className={`bi ${props.icon ? props.icon : 'bi-filetype-pdf'}`}></i> {props.text}</>
            }
        </Button>
    )
}