import React, {useEffect, useState} from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import {Col, Container, ListGroup, Pagination, Row} from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import {Notificacion, Pagina, Paginacion} from "../../types/servicio";
import {getPaginaNotificaciones} from "../../services/conem-services";
import {formatoFechaTiempo, getUsuarioSesion, OK} from "../../utils";

export default function NotificacionesIndex() {

    const [loading, setLoading] = useState<boolean>(false);

    const [paginacion, setPaginacion] = useState<Paginacion<Notificacion>>({
        items: [],
        numberOfElements: 0,
        page: 0,
        totalElements: 0,
        totalPages: 0
    });

    const [pagina, setPagina] = useState<Pagina>({currentPage: 0, rowsPerPage: 10});

    const mostrarPagina = (indice: number) => {
        const siguientePagina = {...pagina, currentPage: indice}
        setPagina(siguientePagina);
        cargarPagina(siguientePagina);
    }

    const cargarPagina = (pagina: Pagina) => {
        setLoading(true);
        getPaginaNotificaciones(getUsuarioSesion().id, pagina).then(resp => {
            if (resp.statusCode === OK) {
                setPaginacion(resp.data);
            } else {
            }
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        cargarPagina(pagina);
    }, [])


    return (<>
        <LoadingSpinner show={loading}/>
        <Container>
            <PageHeader label="Notificaciones"/>
            <hr/>
            {
                (!paginacion || paginacion.items.length == 0) &&
                <div className='d-flex justify-content-center' style={{marginTop: '20vh'}}>
                    <span className='fs-2 fw-bold' style={{color: '#d0cece'}}>No hay notificaciones nuevas</span>
                </div>
            }
            {
                (paginacion && paginacion.items.length > 0) && <>
                    <ListGroup>
                        <ListGroup.Item className="list-header">
                            <Row className="fw-bold">
                                <Col sm='9'>
                                    Mensaje
                                </Col>
                                <Col>
                                    <div className='d-flex justify-content-center'>
                                        Fecha y hora
                                    </div>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        {
                            paginacion.items.map((notificacion: Notificacion) => {
                                return <ListGroup.Item>
                                    <Row>
                                        <Col sm='9'>
                                            <span>{notificacion.mensaje}</span>
                                        </Col>
                                        <Col>
                                            <div className='d-flex justify-content-center'>
                                                <span> {formatoFechaTiempo(notificacion.generado)}</span>
                                            </div>

                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </>
            }
            <div className="d-flex justify-content-center" style={{marginTop: "10px"}}>
                <Pagination>
                    {paginacion.totalPages > 1 && Array(paginacion.totalPages).fill(1).map((el, i) =>
                        <Pagination.Item active={i === paginacion.page} onClick={() => {
                            if (i !== paginacion.page) {
                                mostrarPagina(i);
                            }
                        }}>{i + 1}</Pagination.Item>
                    )}
                </Pagination>
            </div>
            {
                paginacion.numberOfElements > 0 &&
                <div className="d-flex justify-content-center" style={{marginTop: "5px", marginBottom: "30px"}}>
                    <span style={{fontSize: "14px"}}>
                        Registros {((paginacion.page) * 10) + 1} al {((paginacion.page) * 10) + paginacion.numberOfElements} - Página {paginacion.page + 1} de {paginacion.totalPages} - Total de registros {paginacion.totalElements}
                    </span>
                </div>
            }

        </Container>
    </>)
}