import {Button, Container, Form, Row} from "react-bootstrap";
import CheckBoxFilter from "../common/CheckBoxFilter";
import React, {useState} from "react";
import {FiltroCotizacion} from "../../types/servicio";
import {formatoFechaSimple} from "../../utils";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import {omit} from "lodash";

registerLocale('es', es)

export default function FiltroCotizaciones(props: { aplicarFiltro: (filtroCotizacion: FiltroCotizacion) => void }) {

    const [fechaRegistro, setFechaRegistro] = useState<Date | null>(null);
    const [nombreContacto, setNombreContacto] = useState<string>('');
    const [folio, setFolio] = useState<string>('');
    const [guardados, setGuardados] = useState<boolean>(false);
    const [enviados, setEnviados] = useState<boolean>(false);
    const [rechazados, setRechazados] = useState<boolean>(false);
    const [expirados, setExpirados] = useState<boolean>(false);
    const [riesgoBajo, setRiesgoBajo] = useState(false);
    const [riesgoMedio, setRiesgoMedio] = useState(false);
    const [riesgoAlto, setRiesgoAlto] = useState(false);
    const [errores, setErrores] = useState<any>({});

    return (
        <>
            <div className='filter'>
                <Button variant='outline-dark' size='sm'>
                    <i className="bi bi-funnel-fill"/> Filtrar
                </Button>
                <div className='filter-content rounded'>
                    <Row>
                        <span className="text-muted"
                              style={{fontSize: "0.8rem", marginLeft: "0px"}}>Fecha de registro</span>
                    </Row>
                    <Container>
                        <Row>
                            <DatePicker dateFormat='dd/MM/yyyy'
                                className="text-muted rounded filter-date" selected={fechaRegistro}
                                locale="es"
                                isClearable
                                onChange={(date: Date) => {
                                    setFechaRegistro(date);
                                }}/>
                        </Row>
                    </Container>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Nombre del contacto</span>
                    </Row>
                    <Container>
                        <Row className="row-cols-auto">
                            <Form.Control type="text" style={{width: "300px"}} value={nombreContacto}
                                          maxLength={300}
                                          isInvalid={!!errores.nombreContacto}
                                          onChange={(e) => {
                                              setNombreContacto(e.target.value)
                                          }}/>
                            <Form.Control.Feedback type='invalid'>{errores.nombreContacto}</Form.Control.Feedback>
                        </Row>
                    </Container>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Folio</span>
                    </Row>
                    <Container>
                        <Row className="row-cols-auto">
                            <Form.Control type="text" style={{width: "300px"}} value={folio}
                                          maxLength={100} isInvalid={!!errores.folio}
                                          onChange={(e) => {
                                              setFolio(e.target.value)
                                          }}/>
                            <Form.Control.Feedback type='invalid'>{errores.folio}</Form.Control.Feedback>
                        </Row>
                    </Container>
                    <hr/>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Status de la cotización</span>
                    </Row>
                    <Container>
                        <Row>
                            <CheckBoxFilter label="Guardado" id="guardado" onChange={(checked: boolean) => {
                                setGuardados(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Enviado" id="enviado" onChange={(checked: boolean) => {
                                setEnviados(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Rechazado" id="rechazado" onChange={(checked: boolean) => {
                                setRechazados(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Expirado" id="expirado" onChange={(checked: boolean) => {
                                setExpirados(checked);
                            }}/>
                        </Row>
                    </Container>
                    <hr/>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Nivel de riesgo</span>
                    </Row>
                    <Container>
                        <Row>
                            <CheckBoxFilter label="Bajo" id="bajo" onChange={(checked: boolean) => {
                                setRiesgoBajo(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Medio" id="medio" onChange={(checked: boolean) => {
                                setRiesgoMedio(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Alto" id="alto" onChange={(checked: boolean) => {
                                setRiesgoAlto(checked);
                            }}/>
                        </Row>
                    </Container>
                    <hr/>
                    <div className='d-flex justify-content-center' style={{paddingTop: "5px"}}>
                        <Button variant="primary" size='sm' style={{width: '150px'}} onClick={() => {
                            let newErrores = {...errores};
                            let accentedCharacters = "áéíóúÁÉÍÓÚñÑ";
                            const tmpNombreContacto = nombreContacto.trim();
                            const tmpFolio = folio.trim();
                            if (tmpNombreContacto) {
                                if (tmpNombreContacto.match("^[A-Za-z" + accentedCharacters + "]+[A-Za-z\\s" + accentedCharacters + "]*$") === null) {
                                    newErrores = {
                                        ...newErrores,
                                        nombreContacto: "El nombre de contacto debe iniciar con una letra y no debe contener caracteres especiales"
                                    };
                                } else {
                                    newErrores = omit(newErrores, 'nombreContacto');
                                }
                            } else {
                                newErrores = omit(newErrores, 'nombreContacto');
                            }
                            if (tmpFolio) {
                                if (tmpFolio.match("^[A-Za-z0-9]+(\\-[A-Za-z0-9]+)*$") === null) {
                                    newErrores = {
                                        ...newErrores,
                                        folio: "El folio debe iniciar con una letra o número y puede contener guiones"
                                    };
                                } else {
                                    newErrores = omit(newErrores, 'folio');
                                }
                            } else {
                                newErrores = omit(newErrores, 'folio');
                            }
                            setErrores(newErrores);
                            if (Object.keys(newErrores).length === 0) {
                                props.aplicarFiltro({
                                    rowsPerPage: 10,
                                    currentPage: 0,
                                    guardados: guardados,
                                    enviados: enviados,
                                    rechazados: rechazados,
                                    expirados: expirados,
                                    riesgoBajo: riesgoBajo,
                                    riesgoMedio: riesgoMedio,
                                    riesgoAlto: riesgoAlto,
                                    nombreContacto: tmpNombreContacto,
                                    fechaRegistro: formatoFechaSimple(fechaRegistro ? fechaRegistro.toString() : null),
                                    folio: tmpFolio
                                });
                            }
                        }}>
                            Aplicar filtro
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}