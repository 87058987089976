import ArchivoItem from "./ArchivoItem";
import {Button} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {Archivo} from "../../types/servicio";
import {uploadTmpFile} from "../../services/conem-services";
import {FILE_MAX_SIZE} from "../../utils";
import FileSelector from "../FileSelector/FileSelector";


export default function FileChooser(
    props: {
        archivo?: Archivo | null,
        buttonUploadLabel: string,
        disabled:boolean,
        updatedFile:(file:Archivo)=>void,
    }
) {

    const [archivo, setArchivo] = useState<Archivo | null>(null);

    const hiddenFileInputRef = useRef(null);

    useEffect(() => {
        if (props.archivo) {
            setArchivo(props.archivo);
        }
    }, [props.archivo]);


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i);
                if (file && (file.size <= 0 || file.size >= FILE_MAX_SIZE)) {
                    valid = false;
                    //mostrarMensajeError("El tamaño mínimo de archivo es 1 byte y menor a 100 MB");
                    break;
                }
            }
        }
        if (e.target.files && valid) {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            setArchivo({
                subidoPor:'',
                nombre:e.target.files[0].name,
                tamanio:0,
                dropboxLink: null,
                fechaSubido: "",
                folder: '',
                id: '',
                seleccionado: false,
                url: ""
            })
            uploadTmpFile(formData).then(resp=>{
                if (resp.statusCode===200){
                    setArchivo(resp.data);
                    props.updatedFile(resp.data);
                }else{
                    setArchivo(null);
                }
            }).catch(err=>{
                setArchivo(null);
            }).finally();
        }
    };


    const clickSelectFiles = () => {
        if (hiddenFileInputRef && hiddenFileInputRef.current) {
            // @ts-ignore
            hiddenFileInputRef.current.click();
        }
    }


    return (
        <div style={{marginBottom: "30px", marginLeft: '5px', marginTop: '10px'}}>
            <input id="file" type="file" multiple={true} ref={hiddenFileInputRef} style={{display: 'none'}}
                   onChange={handleFileChange}/>
            {
                archivo &&
                <ArchivoItem archivo={archivo} servicioId={''}
                             documentoId={''} statusServicio={''} seleccionable={false} eliminable={false}/>
            }
            <div style={{marginTop:'10px'}}>
                <Button size='sm' disabled={props.disabled} hidden={props.disabled} variant='outline-dark' onClick={clickSelectFiles}>
                    {props.buttonUploadLabel}
                </Button>
            </div>
        </div>
    )
}