import {Accordion, Col, ListGroup, Row, Tab, Tabs} from "react-bootstrap";
import React, {useEffect} from "react";
import {getAnioActual, getBimestreActual, getMesActual} from "../../utils";
import {Grupo, Usuario} from "../../types/servicio";
import RoundedDashedBox from "../rounded-dashed-box/RoundedDashedBox";

interface Cobranza {
    creditoAutorizado: number[];
    anticipoPendientes: number[];
    liquidacionesPendientes: number[];
}

interface Conversion {
    enviadasClientesNuevo: number[];
    clientesNuevoProceso: number[];
    clientesNuevoDeclinadas: number[];
    clientesNuevoAutorizadas: number[];
    porcentajeConversion: number[];
    porcentajeDeclinacion: number[];
}

interface Fidelizacion {
    cotizacionesEnviadasClientesRenovacion: number[];
    cotizacionesClientesRenovacionEnProceso: number[];
    cotizacionesClientesRenovacionDeclinadas: number[];
    cotizacionesClientesRenovacionAutorizadas: number[];
    porcentajeConversion: number[];
    porcentajeDeclinacion: number[];
}

interface OtrosServicios {
    cotizacionesEnviadas: number[];
    cotizacionesEnProceso: number[];
    cotizacionesDeclinadas: number[];
    cotizacionesAutorizadas: number[];
    porcentajeConversion: number[];
    porcentajeDeclinacion: number[];
}

interface DatosOperativosMes {
    capacitacionesRealizadas: number;
    pipcIngresados: number;
    anuenciasLiberadas: number;
    tiempoMaximoDesarrollo: number;
    tiempoMinimoDesarrollo: number;
    tiempoPromedioDesarrollo: number;
    cumplimientoTiempoForma: number;
    totalServicios: number;
    pipcEnDesarrollo: number;
    pipcAtrasados: number;
    porcentajeCumplimientoTiempoForma: number;
    porcentajeObservacionesAutoridad: number;
}

interface EstadisticaOperativaIndividual {
    operativo: Usuario;
    datosOperativos: DatosOperativosMes[];
}

interface EstadisticaOperativaGeneral {
    datosOperativos: DatosOperativosMes[];
}

interface Estadistica {
    id: string;
    anio: number;
    cobranza: Cobranza;
    conversion: Conversion;
    fidelizacion: Fidelizacion;
    otrosServicios: OtrosServicios;
    estadisticaOperativaIndividual: EstadisticaOperativaIndividual[];
    estadisticaOperativaGeneral: EstadisticaOperativaGeneral;
}

function label(val: number | undefined | null,percentage:boolean, hightlight?: boolean) {
    if (val || val === 0) {
        if (hightlight) {
            return (<div className='fs-3 text-center'
                         style={{backgroundColor: 'rgb(250,253,177)', color: 'rgb(29, 11, 92)'}}>{val}{percentage?' %':''}</div>)
        } else {
            return (<label className='fs-3 text-center'
                           style={{color: val > 0 ? 'rgb(30,132,226)' : 'rgb(110,122,132)'}}>{val}{percentage?' %':''}</label>)
        }

    }
    return <p className="placeholder-glow">
        <span className="placeholder col-6" style={{height: '25px'}}></span>
    </p>
}


export const DashboardAdministrativo = (props: { anio: number, grupo: Grupo | null }) => {

    const [estadistica, setEstadistica] = React.useState<Estadistica | null>(null);

    function getEstadisticas(anio: number, grupoId: string | undefined) {
        return fetch(`/api/servicios/estadisticas?anio=${anio}&${grupoId ? ('grupoId=' + grupoId) : ''}`)
            .then((response) => response.json())
            .then((responseJson) => {
                return responseJson;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        setEstadistica(null);
        getEstadisticas(props.anio, props.grupo?.id).then(response => {
                setEstadistica(response);
            }
        ).catch(err => {
            console.log(err);
        });
    }, [props.anio, props.grupo])


    const getRow = (description: string, values: number[] | undefined | null,percentage?:boolean) => {
        const bimestreActual = getBimestreActual() - 1;
        const anioActual = getAnioActual() === props.anio;
        return (
            <Row>
                <Col className='estadistica_columna'>{description}</Col>
                <Col>
                    {label(values ? values[0] : undefined, percentage===true,bimestreActual === 0 && anioActual)}
                </Col>
                <Col>
                    {label(values ? values[1] : undefined, percentage===true,bimestreActual === 1 && anioActual)}
                </Col>
                <Col>
                    {label(values ? values[2] : undefined,percentage===true, bimestreActual === 2 && anioActual)}
                </Col>
                <Col>
                    {label(values ? values[3] : undefined, percentage===true,bimestreActual === 3 && anioActual)}
                </Col>
                <Col>
                    {label(values ? values[4] : undefined,percentage===true, bimestreActual === 4 && anioActual)}
                </Col>
                <Col>
                    {label(values ? values[5] : undefined, percentage===true,bimestreActual === 5 && anioActual)}
                </Col>
            </Row>
        )
    }

    const meses = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ]

    function rowDatosOperativos(datosOperativos: DatosOperativosMes | undefined, mes: number) {
        const highlitght = mes === getMesActual();
        const nombreMes = meses[mes];
        return (<ListGroup.Item>
            <Row>
                <Col className='estadistica_columna'>{nombreMes}</Col>
                <Col>{label(datosOperativos?.capacitacionesRealizadas, false,highlitght)}</Col>
                <Col>{label(datosOperativos?.pipcIngresados, false,highlitght)}</Col>
                <Col>{label(datosOperativos?.pipcAtrasados, false,highlitght)}</Col>
                <Col>{label(datosOperativos?.anuenciasLiberadas, false,highlitght)}</Col>
                <Col>{label(datosOperativos?.tiempoPromedioDesarrollo, false,highlitght)}</Col>
                <Col>{label(datosOperativos?.porcentajeCumplimientoTiempoForma, true,highlitght)}</Col>
                <Col>{label(datosOperativos?.porcentajeObservacionesAutoridad, true,highlitght)}</Col>
            </Row>
        </ListGroup.Item>)
    }

    function dashboardOperativo(estadisticaOperativa: EstadisticaOperativaIndividual | EstadisticaOperativaGeneral | undefined) {
        return (
            <ListGroup style={{marginBottom: '30px'}}>
                <ListGroup.Item style={{backgroundColor: '#dadada', color: 'black'}}
                                key={'header'}>
                    <Row>
                        <Col></Col>
                        <Col>Capacitaciones realizadas</Col>
                        <Col>PIPC Ingresados</Col>
                        <Col>PIPC Atrasados</Col>
                        <Col>Anuencias Liberadas</Col>
                        <Col>Tiempo de desarrollo promedio</Col>
                        <Col>Objetivo 1. Cumplimiento en tiempo y forma (80%)</Col>
                        <Col>Objetivo 2. Observaciones de la autoridad.(80%)</Col>
                    </Row>
                </ListGroup.Item>
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[0], 0)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[1], 1)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[2], 2)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[3], 3)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[4], 4)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[5], 5)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[6], 6)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[7], 7)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[8], 8)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[9], 9)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[10], 10)}
                {rowDatosOperativos(estadisticaOperativa?.datosOperativos[11], 11)}
            </ListGroup>)
    }

    function rowOperativo(operativo: Usuario, index: number) {
        return (<Accordion.Item eventKey={`${index}`}>
            <Accordion.Header>
                <div style={{marginLeft: '10px'}}>
                    {operativo.nombreCompleto}
                </div>
            </Accordion.Header>
            <Accordion.Body>
                {dashboardOperativo(estadistica?.estadisticaOperativaIndividual[index])}
            </Accordion.Body>
        </Accordion.Item>)
    }


    return (<>
            <center><label className='fs-5'>Cobranza</label></center>
            <ListGroup style={{marginBottom: '30px'}}>
                <ListGroup.Item style={{backgroundColor: '#dadada', color: 'black'}}
                                key={'header'}>
                    <Row>
                        <Col></Col>
                        <Col>Enero-Febrero</Col>
                        <Col>Marzo-Abril</Col>
                        <Col>Mayo-Junio</Col>
                        <Col>Julio-Agosto</Col>
                        <Col>Septiembre-Octubre</Col>
                        <Col>Noviembre-Diciembre</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('# créditos autorizados', estadistica?.cobranza?.creditoAutorizado)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('# de anticipos pendientes', estadistica?.cobranza?.anticipoPendientes)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('# de liquidaciones pendientes', estadistica?.cobranza?.liquidacionesPendientes)}
                </ListGroup.Item>
            </ListGroup>

            <center><label className='fs-5'>Objetivo de conversión de nuevos clientes(20%)</label></center>
            <ListGroup style={{marginTop: '10px', marginBottom: '30px'}}>
                <ListGroup.Item style={{backgroundColor: '#dadada', color: 'black'}}
                                key={'header'}>
                    <Row>
                        <Col></Col>
                        <Col>Enero-Febrero</Col>
                        <Col>Marzo-Abril</Col>
                        <Col>Mayo-Junio</Col>
                        <Col>Julio-Agosto</Col>
                        <Col>Septiembre-Octubre</Col>
                        <Col>Noviembre-Diciembre</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones enviadas clientes nuevos', estadistica?.conversion?.enviadasClientesNuevo)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones autorizadas clientes nuevos', estadistica?.conversion?.clientesNuevoAutorizadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones de clientes nuevos en proceso', estadistica?.conversion?.clientesNuevoProceso)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones declinadas de clientes nuevos', estadistica?.conversion?.clientesNuevoDeclinadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('% de conversión de clientes nuevos', estadistica?.conversion?.porcentajeConversion,true)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('% de declinación de clientes nuevos', estadistica?.conversion?.porcentajeDeclinacion,true)}
                </ListGroup.Item>
            </ListGroup>
            <center><label className='fs-5'>Objetivo de fidelización de clientes(75%)</label></center>
            <ListGroup style={{marginTop: '10px', marginBottom: '30px'}}>
                <ListGroup.Item style={{backgroundColor: '#dadada', color: 'black'}}
                                key={'header'}>
                    <Row>
                        <Col></Col>
                        <Col>Enero-Febrero</Col>
                        <Col>Marzo-Abril</Col>
                        <Col>Mayo-Junio</Col>
                        <Col>Julio-Agosto</Col>
                        <Col>Septiembre-Octubre</Col>
                        <Col>Noviembre-Diciembre</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones enviadas clientes de renovación', estadistica?.fidelizacion?.cotizacionesEnviadasClientesRenovacion)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones de clientes de renovación autorizadas', estadistica?.fidelizacion?.cotizacionesClientesRenovacionAutorizadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones de clientes de renovación en proceso', estadistica?.fidelizacion?.cotizacionesClientesRenovacionEnProceso)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones declinadas de clientes de renovación', estadistica?.fidelizacion?.cotizacionesClientesRenovacionDeclinadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('% de conversión de clientes de renovación', estadistica?.fidelizacion?.porcentajeConversion,true)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('% de declinación de clientes de renovación', estadistica?.fidelizacion?.porcentajeDeclinacion,true)}
                </ListGroup.Item>
            </ListGroup>

            <center><label className='fs-5'>Otros servicios</label></center>
            <ListGroup style={{marginTop: '10px', marginBottom: '30px'}}>
                <ListGroup.Item style={{backgroundColor: '#dadada', color: 'black'}}
                                key={'header'}>
                    <Row>
                        <Col></Col>
                        <Col>Enero-Febrero</Col>
                        <Col>Marzo-Abril</Col>
                        <Col>Mayo-Junio</Col>
                        <Col>Julio-Agosto</Col>
                        <Col>Septiembre-Octubre</Col>
                        <Col>Noviembre-Diciembre</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones enviadas', estadistica?.otrosServicios?.cotizacionesEnviadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones autorizadas', estadistica?.otrosServicios?.cotizacionesAutorizadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones en proceso', estadistica?.otrosServicios?.cotizacionesEnProceso)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('Cotizaciones declinadas', estadistica?.otrosServicios?.cotizacionesDeclinadas)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('% de conversión de otros servicios', estadistica?.otrosServicios?.porcentajeConversion,true)}
                </ListGroup.Item>
                <ListGroup.Item>
                    {getRow('% de declinación de otros servicios', estadistica?.otrosServicios?.porcentajeDeclinacion,true)}
                </ListGroup.Item>
            </ListGroup>


            <center><label className="fs-5">Estadística Operativa</label></center>

            <Tabs defaultActiveKey='general' id='general'
                  className='mb-3' style={{marginTop: '10px'}}>
                <Tab eventKey='general' title='General'>
                    {dashboardOperativo(estadistica?.estadisticaOperativaGeneral)}
                </Tab>
                <Tab eventKey='individual' title='Individual'>
                    {
                        estadistica && estadistica.estadisticaOperativaIndividual.length > 0 ?
                            <>
                                <Accordion defaultActiveKey="0">
                                    {
                                        estadistica?.estadisticaOperativaIndividual.map((operador, index) => {
                                            return rowOperativo(operador.operativo, index)
                                        })
                                    }
                                </Accordion>

                            </> : <RoundedDashedBox label='No se encontraron registros'/>

                    }
                </Tab>
            </Tabs>
        </>
    )
};