import {useState} from "react";
import cloneDeep from "lodash.clonedeep";
import {getFolio} from "../services/cotizacion.services";
import {NOT_FOUND, OK} from "../utils";

interface Secuencia{
    base:number;
    actual:number;
}

export function useSecuencias(){

    const [secuencias,setSecuencias] = useState<Map<string,Secuencia>>(new Map())

    return {
        //clave folio es=nombrecomercial+id servicio
        getCurrentSecuencia: async (idFolio: string) => {
            let sec=secuencias.get(idFolio);
            if (!sec) {
                let newSecuencia = cloneDeep(secuencias);
                let actual = 1;
                try {
                    const resp = await getFolio(idFolio);
                    if (resp.statusCode === OK) {
                        newSecuencia.set(idFolio, {actual: resp.data.secuenciaFolio, base: resp.data.secuenciaFolio});
                        actual = resp.data.secuenciaFolio;
                    } else {
                        newSecuencia.set(idFolio, {actual: actual, base: actual});
                    }
                }catch (err){
                    newSecuencia.set(idFolio, {actual: actual, base: actual});
                }
                setSecuencias(newSecuencia);
                return actual;
            }
            return sec.actual;
        },
        incrementSecuencia: (idFolio: string):number => {
            let sec=secuencias.get(idFolio);
            if (sec){
                const actual=sec.actual+1;
                let newSecuencias = cloneDeep(secuencias);
                newSecuencias.set(idFolio,{actual:actual,base:sec.base});
                setSecuencias(newSecuencias);
                return actual;
            }else{
                let newSecuencia = cloneDeep(secuencias);
                newSecuencia.set(idFolio, {actual:1,base:1});
                setSecuencias(newSecuencia);
                return 1;
            }
        },
        decrementSecuencia: (idFolio: string) => {
            let sec=secuencias.get(idFolio);
            if (sec){
                const actual=sec.actual-1;
                let newSecuencia = cloneDeep(secuencias);
                newSecuencia.set(idFolio, {actual:actual,base:sec.base});
                setSecuencias(newSecuencia);
                return actual;
            }
            return -1;
        },
        getBase:(idFolio:string)=>{
            let sec=secuencias.get(idFolio);
            if (sec) {
                return sec.base;
            }
            return -1;
        },
        clear:()=>{
            setSecuencias(new Map());
        }
    }
}