import {ACTIVIDAD_STATUS_EN_PROGRESO, ACTIVIDAD_STATUS_FINALIZADO, formatoFechaTexto} from "../../utils";
import React from "react";
import {Servicio} from "../../types/servicio";


export default function LabelFechaEstimada(props:{
    servicio:Servicio
}){
    return (<>
        {
            (props.servicio.status !== 'finalizado'  && props.servicio.fechaEstimada &&
                (props.servicio.actividades[5].status !== ACTIVIDAD_STATUS_EN_PROGRESO && props.servicio.actividades[5].status !== ACTIVIDAD_STATUS_FINALIZADO)) &&
            <span>Fecha estimada de ingreso ante la autoridad: <span
                className='fw-bold'>{formatoFechaTexto(props.servicio.fechaEstimada)}</span></span>
        }
        {
            (props.servicio.status !== 'finalizado'  && props.servicio.fechaEstimada &&
                props.servicio.actividades[5].status === ACTIVIDAD_STATUS_FINALIZADO) &&
            <span>Fecha programada de entrega al cliente: <span
                className='fw-bold'>{formatoFechaTexto(props.servicio.fechaEstimada)}</span></span>
        }
        {
            props.servicio.actividades[5].status === ACTIVIDAD_STATUS_EN_PROGRESO &&
            <span>Fecha de ingreso ante la autoridad: <span
                className='fw-bold'>{formatoFechaTexto(props.servicio.actividades[4].fechaFinalizado)}</span></span>
        }

        {
            (props.servicio.status !== 'finalizado'  && !props.servicio.fechaEstimada) &&
            <span>Por el momento la fecha de entrega del servicio no está disponible</span>
        }
    </>)
}