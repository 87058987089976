import {Actividad, Archivo, Observacion, Servicio} from "../../types/servicio";
import React, {useEffect, useRef, useState} from "react";
import {
    ACTIVIDAD_STATUS_EN_PROGRESO,
    ACTIVIDAD_STATUS_FINALIZADO, FILE_MAX_SIZE,
    formatoFechaTexto, formatoFechaTiempo,
    getUsuarioSesion,
    isAdmin,
    isCliente,
    isCoordinador,
    isOperativo,
    isVentas,
    OK
} from "../../utils";
import axios from "axios";
import {
    cancelarActividad,
    cerrarActividad, eliminarArchivo,
    eliminarEvidencias,
    getCsrfToken,
    guardarEvidencias, guardarObservaciones, rechazarActividad
} from "../../services/conem-services";
import {Button, Col, Container, Dropdown, DropdownButton, Form, Row, Spinner} from "react-bootstrap";
import Advertencia from "../mensajes/Advertencia";
import ArchivoItem from "../documento/ArchivoItem";
import ConfirmacionModal from "../common/ConfirmacionModal";
import {eliminarObservaciones} from "../../services/observaciones.services";
import RoundedDashedBox from "../rounded-dashed-box/RoundedDashedBox";

async function getActividades(servicioId: string) {
    if (servicioId) {
        const {data} = await axios.get(`/api/servicios/${servicioId}/actividades`, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        });
        return data;
    }
}

export default function ActividadList(props: {
    servicioId:string,
    folioSecretariaEstatal:string|null,
}) {

    const [actividades,setActividades]=useState<Actividad[]>([]);

    useEffect(() => {
        getActividades(props.servicioId).then(data=>{
            setActividades(data);
        })
    }, [props.servicioId]);

    return (<div className="stepper-container">
            {
                actividades?.map((item: Actividad, idx: number) => {
                    if (item.clave === 'revision.calidad' && isCliente()) {
                        return <></>
                    } else {
                        return (
                            <div
                                className={`step ${item.status === 'en_progreso' ? 'active' : (item.status === 'finalizado' ? 'completed' : '')}`}>
                                <div className="v-stepper">
                                    <div className="stepper-circle"></div>
                                    <div className="stepper-line"></div>
                                </div>
                                <div
                                    className={idx < (actividades.length - 1) ? 'stepper-content' : 'stepper-content'}>
                                    <ActividadItem actividad={item}
                                                   servicioId={props.servicioId}
                                                   folioSecretariaEstatal={props.folioSecretariaEstatal}
                                                  update={(actividades:Actividad[])=>setActividades(actividades)}
                                    />
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>

    );
}


function ActividadItem(
    props: {
        servicioId: string,
        folioSecretariaEstatal:string|null,
        actividad: Actividad,
        showErrorMessage?: (message: string) => void,
        update: (actividades:Actividad[]) => void
    }
) {

    const hiddenFileInput = useRef(null);

    const [confirmarEliminarArchivo, setConfirmarEliminarArchivo] = useState<boolean>(false)
    const [archivoEliminar, setArchivoEliminar] = useState<Archivo | null>(null);

    const [localFilesSelected, setLocalFilesSelected] = useState<any>(null);
    const [archivos, setArchivos] = useState<Archivo[]>([]);
    const [archivosSeleccionados, setArchivosSeleccionados] = useState<Archivo[]>([])
    const [confirmarFinalizarActividad, setConfirmarFinalizarActividad] = useState<boolean>(false);
    const [confirmarCancelarActividad, setConfirmarCancelarActividad] = useState<boolean>(false);
    const [confirmarRechazarActividad, setConfirmarRechazarActividad] = useState<boolean>(false);
    const [eliminandoEvidencias, setEliminandoEvidencias] = useState<boolean>(false);

    const [observacionesAutoridad, setObservacionesAutoridad] = useState<boolean>(false);
    const [folioSecretariaEstatal,setFolioSecretariaEstatal] = useState<string>('');

    useEffect(() => {
        setLocalFilesSelected(null);
        setArchivos(props.actividad.evidencias.archivos);
        setEliminandoEvidencias(false);
        setArchivosSeleccionados([]);
        if (props.actividad.observacionesAutoridad)
            setObservacionesAutoridad(props.actividad.observacionesAutoridad)
        if (props.folioSecretariaEstatal)
        setFolioSecretariaEstatal(props.folioSecretariaEstatal)
    }, [props.actividad,props.folioSecretariaEstatal]);

    const changeSelection = (archivo: Archivo, checked: boolean) => {
        if (!checked) {
            setArchivosSeleccionados(archivosSeleccionados.filter((item: Archivo) => item.id !== archivo.id));
        } else {
            setArchivosSeleccionados([...archivosSeleccionados, archivo]);
        }
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let valid = true;
        if (e.target.files && e.target.files.length > 0) {
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files.item(i);
                if (file && (file.size <= 0 || file.size >= FILE_MAX_SIZE)) {
                    valid = false;
                    showErrorMessage("El tamaño mínimo de archivo es 1 byte y menor a 100 MB");
                    e.target.value = '';
                    return;
                }
            }
            if (valid) {
                const formData = new FormData();
                setLocalFilesSelected(e.target.files);
                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append("files", e.target.files[i]);
                }
                guardarEvidencias(props.servicioId, props.actividad, formData).then(
                    data => {
                        props.update(data)
                    }
                ).catch(err => {
                    showErrorMessage("Lo sentimos ocurrió un error al subir los archivos de evidencias, por favor inténtelo mas tarde");
                }).finally(() => {
                    setLocalFilesSelected(null)
                });

            }
        }
    };

    const clickSelectFiles = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            // @ts-ignore
            hiddenFileInput.current.click();
        }
    }

    const enableActivityOptions = (actividad: Actividad) => {
        if (actividad && actividad.status === 'en_progreso') {

            if (isAdmin() || isVentas()) { //&& actividad.rolesPropietarios.includes("ROLE_ADMIN")) {
                return true;
            }
            if (!isCliente()) {
                return true;
            }
            /*const username = getUsuarioSesionUsername();
            if (isCoordinador() && actividad.rolesPropietarios.includes("ROLE_COORDINADOR")) {
                if (actividad.tipoPropietario === 'individual') {
                    if (props.servicio.coordinador && props.servicio.coordinador.correo === username) {
                        return true;
                    }
                } else if (actividad.tipoPropietario === 'role') {
                    return true;
                }
            }
            if (isOperador() && actividad.rolesPropietarios.includes("ROLE_OPERADOR")) {
                if (actividad.tipoPropietario === 'individual') {
                    if (props.servicio.operador && props.servicio.operador.correo === username) {
                        return true;
                    }
                } else if (actividad.tipoPropietario === 'role') {
                    return true;
                }
            }
            if (isCalidad() && actividad.rolesPropietarios.includes("ROLE_CALIDAD")) {
                if (actividad.tipoPropietario === 'individual') {
                    if (props.servicio.calidad && props.servicio.calidad.correo === username) {
                        return true;
                    }
                } else if (actividad.tipoPropietario === 'role') {
                    return true;
                }
            }*/
        }
        return false;
    }

    const isEnProgreso = () => {
        return props.actividad.status === 'en_progreso';
    }

    const showErrorMessage = (message: string) => {
        if (props.showErrorMessage) {
            props.showErrorMessage(message);
        }
    }

    const isEnabledUploadEvidencias = () => {
        return (getUsuarioSesion() !== null && !isCliente() && props.actividad.status === 'en_progreso');
    }

    const isEnableDeleteEvidencias = () => {
        return (archivosSeleccionados.length > 0 && isEnabledUploadEvidencias());
    }

    return (<div className={isEnProgreso() ? 'rounded' : ''}
                 style={{padding: '10px'}}>
            <input id="file" type="file" multiple={true} ref={hiddenFileInput} style={{display: 'none'}}
                   onChange={handleFileChange}/>
            <Row>
                <Col style={{borderRight: '1px solid #fafafa'}}>
                    <div style={{marginBottom: '10px', paddingRight: '10px'}}>
                        <div style={{marginBottom: '10px'}}>
                            <label className={`fw-bold  fs-6 conem-text-actividad-activa`}>
                                {props.actividad.descripcion}</label>
                        </div>
                        {
                            (getUsuarioSesion() !== null && enableActivityOptions(props.actividad)) &&
                            <div style={{marginLeft: '20px'}}>
                                {
                                    props.actividad.clave === 'validacion.informacion' &&
                                    <>
                                        <Button size='sm' variant='outline-dark'
                                                onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Información validada</Button>

                                        <Button size='sm' variant='outline-dark'
                                                disabled={!isCoordinador() && !isAdmin()}
                                                onClick={() => {
                                                    setConfirmarCancelarActividad(true);
                                                }}>Cancelar actividad</Button>
                                    </>
                                }
                                {
                                    (props.actividad.clave !== 'revision.calidad' && props.actividad.clave !== 'validacion.informacion') &&
                                    <>
                                        <Button size='sm' variant='outline-dark' disabled={archivos.length === 0}
                                                onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Finalizar actividad</Button>
                                        {
                                            (props.actividad.clave !== 'reporte.inspeccion') &&
                                            <Button size='sm' variant='outline-dark'
                                                    disabled={!isCoordinador() && !isAdmin()}
                                                    onClick={() => {
                                                        setConfirmarCancelarActividad(true);
                                                    }}>Cancelar actividad</Button>
                                        }
                                        {
                                            archivos.length === 0 && <div style={{fontSize: '0.7em', color: '#939292'}}>
                                                <span><i className='bi bi-exclamation-circle-fill'></i> Para finalizar una actividad debe agregar al menos un archivo de evidencia</span>
                                            </div>
                                        }

                                    </>
                                }

                                {
                                    props.actividad.clave === 'revision.calidad' &&
                                    <>
                                        {
                                            archivos.length === 0 && <div className='file-size-label'>
                                                <label>Para finalizar una actividad debe agregar al menos un archivo de
                                                    evidencia</label>
                                            </div>
                                        }
                                        <div className='d-inline-flex'>
                                            <DropdownButton
                                                disabled={isVentas() || isOperativo() || archivos.length === 0}
                                                size="sm" title="Actualizar">
                                                <Dropdown.Item eventKey="1" onClick={() => {
                                                    setConfirmarFinalizarActividad(true);
                                                }}>Finalizar actividad</Dropdown.Item>
                                                <Dropdown.Item eventKey="2" onClick={() => {
                                                    setConfirmarRechazarActividad(true);
                                                }}>Rechazar actividad</Dropdown.Item>
                                            </DropdownButton>
                                            <Button size='sm' variant='outline-dark'
                                                    disabled={!isCoordinador() && !isAdmin()}
                                                    onClick={() => {
                                                        setConfirmarCancelarActividad(true);
                                                    }}>Cancelar actividad</Button>
                                        </div>
                                    </>

                                }
                            </div>
                        }
                    </div>

                    <div>
                        {
                            (props.actividad.status === 'en_progreso' && props.actividad.clave === 'liberacion.anuencia') &&
                            <div style={{marginLeft: '20px'}}>
                                <Advertencia showIcon={false}
                                                                           mensaje={'Esta actividad está asignada a la autoridad correspondiente por lo que tomará un\n' +
                                                                               '                                    tiempo ' +
                                                                               '                                    variable para su atención.'}/>
                            </div>
                        }

                        <div style={{marginLeft: '20px', marginBottom: '10px'}}>
                            <label style={{fontSize: '0.9em'}}>
                                Fecha estimada de finalización: <span
                                className='fw-bold'>{(props.actividad.clave !== 'liberacion.anuencia' && props.actividad.fechaEstimada) ? formatoFechaTexto(props.actividad.fechaEstimada) : '------'}</span>
                            </label>
                        </div>

                        {
                            props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO &&
                            <div style={{marginBottom: '10px', marginLeft: '20px'}}>
                                <label style={{fontSize: '0.9em'}}>
                                    Fecha real de finalización: <span
                                    className='fw-bold'>{props.actividad.status === 'finalizado' ? formatoFechaTexto(props.actividad?.fechaFinalizado) : '------'}</span>
                                </label>
                            </div>
                        }
                    </div>


                    <div style={{marginLeft: '20px'}}>
                        {
                            (props.actividad.clave === 'ingreso.pc' && (props.actividad.status==='finalizado' || props.actividad.status==='en_progreso')) && <>
                                <Form.Group className="col-md"
                                            style={{marginBottom: "20px"}}>
                                    <Form.Label style={{fontSize:'0.9em'}}>Folio de la secretaría estatal</Form.Label>
                                    <Form.Control type="text"
                                                  readOnly={props.actividad.status!=='en_progreso'}
                                                  className="formFont"
                                                  maxLength={30}
                                                  style={{fontSize:'0.9em'}}
                                                  isInvalid={false}
                                                  value={folioSecretariaEstatal}
                                                  onChange={(e) => setFolioSecretariaEstatal(e.target.value)}
                                                  onBlur={() => {

                                                  }}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{''}</Form.Control.Feedback>
                                </Form.Group>
                            <div className="mb-3">
                                <Form.Check style={{fontSize:'0.9em'}}
                                    checked={observacionesAutoridad}
                                            disabled={props.actividad.status!=='en_progreso'}
                                    onChange={(e) => {
                                        setObservacionesAutoridad(e.target.checked);
                                    }} inline label='Hubo observaciones de la autoridad'/>
                            </div>
                            </>
                        }

                        <ObservacionItem eliminable={props.actividad.status==='en_progreso'}
                            actividad={props.actividad}
                            servicioId={props.servicioId}
                            observaciones={props.actividad.observaciones?props.actividad.observaciones:[]}
                            update={props.update}/>
                    </div>

                </Col>


                <Col style={{marginLeft: '10px'}}>
                    {(props.actividad.clave !== 'validacion.informacion' && !(isCliente() && props.actividad.clave !== 'revision.calidad')) && <>
                        <Row>
                            <Col>
                                <label
                                    className={`fw-bold fs-6`}>
                                    Evidencias
                                </label>
                            </Col>
                            <Col sm='8'>
                                <div className='d-flex justify-content-end'>
                                    {
                                        isEnabledUploadEvidencias() &&
                                        <Button variant="outline-dark" size='sm' className='link-accion'
                                                disabled={localFilesSelected !== null}
                                                onClick={clickSelectFiles}>
                                            {
                                                localFilesSelected === null ? <>Agregar archivos</> : <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    <span>Guardando archivos...</span></>
                                            }
                                        </Button>
                                    }

                                    {
                                        isEnableDeleteEvidencias() &&
                                        <Button variant="outline-dark" size='sm' className='link-accion'
                                                onClick={() => {
                                                    setEliminandoEvidencias(true);
                                                    eliminarEvidencias(props.servicioId, props.actividad, archivosSeleccionados).then(
                                                        data => {
                                                            props.update(data)
                                                        }
                                                    ).catch(err => {
                                                        showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar las archivos seleccionados, por favor inténtelo más tarde');
                                                    }).finally(() => {
                                                        setEliminandoEvidencias(false)
                                                    });

                                                }}>
                                            {
                                                eliminandoEvidencias ? <><Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                /><span>Eliminando archivos...</span></> : <>Eliminar archivos</>

                                            }
                                        </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    (archivos.length === 0 && props.actividad.status === 'en_progreso') && <RoundedDashedBox label='Sin archivos' sm={true}/>

                                }
                                {
                                    archivos.map((archivo: Archivo) => {
                                        return <ArchivoItem archivo={archivo}
                                                            servicioId={''}
                                                            documentoId={''}
                                                            statusServicio={'finalizado'}
                                                            seleccionable={props.actividad.status !== 'finalizado'}
                                                            eliminable={getUsuarioSesion() !== null && props.actividad.status !== 'finalizado'}
                                                            onDelete={(item: Archivo) => {
                                                                setArchivoEliminar(item);
                                                                setConfirmarEliminarArchivo(true);
                                                            }}
                                                            onSelect={(checked: boolean) => {
                                                                changeSelection(archivo, checked);
                                                            }}></ArchivoItem>
                                    })
                                }
                                {
                                    localFilesSelected &&
                                    [...localFilesSelected]?.map((file: any) =>
                                        <ArchivoItem archivo={file}
                                                     servicioId={''}
                                                     documentoId={''}
                                                     statusServicio={'finalizado'}
                                                     seleccionable={false} eliminable={false}
                                                     onSelect={() => {
                                                     }}/>
                                    )
                                }
                            </Col>

                        </Row>

                    </>
                    }
                </Col>

            </Row>


            <ConfirmacionModal show={confirmarFinalizarActividad} onCancel={() => setConfirmarFinalizarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                cerrarActividad(props.servicioId, props.actividad,observacionesAutoridad,folioSecretariaEstatal).then(data => {
                    props.update(data);
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar cerrar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarFinalizarActividad(false);
                })

            }} message={
                [`¿Está seguro de finalizar la actividad ${props.actividad.descripcion}?`]}
            />


            <ConfirmacionModal show={confirmarCancelarActividad} onCancel={() => setConfirmarCancelarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                cancelarActividad(props.servicioId, props.actividad).then(data => {
                    props.update(data)
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar cancelar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarCancelarActividad(false);
                })

            }} message={
                [`¿Está seguro de cancelar la actividad ${props.actividad.descripcion}?`]}
            />

            <ConfirmacionModal show={confirmarRechazarActividad} onCancel={() => setConfirmarRechazarActividad(false)}
                               title={'Confirmación'} onAccept={() => {
                rechazarActividad(props.servicioId, props.actividad).then(data => {
                    props.update(data)
                }).catch(err => {
                    showErrorMessage('Lo sentimos ocurrió un error al intentar rechazar la actividad, por favor inténtelo más tarde');
                }).finally(() => {
                    setConfirmarRechazarActividad(false);
                })

            }} message={
                [`¿Está seguro de rechazar la actividad ${props.actividad.descripcion}?`]}
            />
            {
                archivoEliminar &&
                <ConfirmacionModal show={confirmarEliminarArchivo}
                                   onCancel={() => setConfirmarEliminarArchivo(false)}
                                   title={'Confirmación'} onAccept={() => {

                    setConfirmarEliminarArchivo(false);
                    eliminarArchivo(archivoEliminar).then(data => {
                        props.update(data)
                    }).catch(err => {
                        showErrorMessage('Lo sentimos ocurrió un error al intentar eliminar el archivo, por favor inténtelo más tarde');
                    }).finally(() => {
                        setArchivoEliminar(null);
                    })
                }} message={[`Esta seguro de eliminar el archivo de evidencias ${archivoEliminar.nombre}`]}/>


            }
        </div>
    )
}

function ObservacionItem(
    props: { eliminable:boolean,observaciones: Observacion[],actividad:Actividad, servicioId: string ,update:(actividades:Actividad[]) => void}
) {

    const [editando, setEditando] = useState<boolean>(false);
    const [actualizando, setActualizando] = useState<boolean>(false);
    const [nuevaObservacion,setNuevaObservacion] = useState<string>('');

    useEffect(() => {
        setEditando(false)
        setActualizando(false);
    }, []);

    const submit = () => {
        if(nuevaObservacion.trim()){
            setActualizando(true);
            guardarObservaciones(props.servicioId, props.actividad, nuevaObservacion).then(data => {
                props.update(data);
            }).catch(err => {

            }).finally(() => {
                setNuevaObservacion('')
                setActualizando(false);
            });
        }
    }

    return (
        <>
            <div>
                {
                    props.observaciones.length > 0 ? <>
                            {
                                props.observaciones.map((item) => {
                                    return (
                                        <div className='rounded' style={{border:'1px solid #d9dbde', background: 'rgba(254,242,242,0.78)', marginBottom: '4px'}}>
                                            <Row>
                                                <Col>
                                                    <div style={{padding:'10px'}}>
                                                        <p style={{
                                                            fontSize: '0.9em',
                                                            color: '#3c3c3c'
                                                        }}>{item.descripcion}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Container>
                                                        <Row>
                                                            <Col sm='10'>
                                                                <div>
                                                                    <label style={{
                                                                        fontSize: '0.7em',
                                                                        color: '#939292'
                                                                    }}>{formatoFechaTiempo(item.fecha)}</label>
                                                                </div>
                                                                <div>
                                                                    <label style={{
                                                                        fontSize: '0.7em',
                                                                        color: '#939292'
                                                                    }}>{item.usuario.correo}</label>
                                                                </div>

                                                            </Col>
                                                            {
                                                                props.eliminable&& <Col>
                                                                    <a className='file-size-label' onClick={() => {
                                                                        if (props.actividad.id) {
                                                                            eliminarObservaciones(props.servicioId, props.actividad.id, item.id).then(
                                                                                data => {
                                                                                    props.update(data);
                                                                                }
                                                                            ).catch(err => console.log(err))
                                                                        }

                                                                    }}>Eliminar</a>
                                                                </Col>
                                                            }

                                                        </Row>
                                                    </Container>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                            {
                                (!isCliente() && getUsuarioSesion()) !== null &&
                                <>
                                    <Button variant="outline-dark" size='sm' className='link-accion'
                                            disabled={actualizando} style={{marginTop: '5px'}}
                                            hidden={(props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO || editando)}
                                            onClick={() => {
                                                setEditando(true);
                                            }}>
                                        Agregar observaciones
                                    </Button>
                                </>
                            }
                        </> :
                        <>
                            {
                                (!isCliente() && getUsuarioSesion() !== null && props.actividad.status === ACTIVIDAD_STATUS_EN_PROGRESO) &&
                                <Button variant="outline-dark" size='sm' className='link-accion'
                                        disabled={actualizando} onClick={() => {
                                    setEditando(true);
                                }}>
                                    Agregar observaciones
                                </Button>
                            }
                        </>
                }
            </div>
            {
                editando &&
                <div>
                    <Form.Group className="mb-3" controlId="observaciones">
                        <Form.Label><label style={{fontSize: '0.9em'}}>Observaciones:</label></Form.Label>
                        <Form.Control as="textarea" style={{fontSize: '0.9em'}} rows={3} value={nuevaObservacion}
                                      onChange={(e) => setNuevaObservacion(e.target.value)}/>
                    </Form.Group>
                    <Button variant="outline-danger" style={{width: '150px'}} size='sm' className='link-accion'
                            hidden={props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}
                            disabled={actualizando} onClick={() => {
                        setEditando(false);
                    }}>
                        Cerrar
                    </Button>
                    <Button variant="outline-dark" size='sm' className='link-accion'
                            disabled={actualizando} onClick={submit}
                            hidden={props.actividad.status === ACTIVIDAD_STATUS_FINALIZADO}>
                        {
                            !actualizando ? <>Guardar observaciones</> : <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Guardando observaciones</span>
                            </>
                        }
                    </Button>
                </div>
            }
        </>

    )
}