import React from "react";

export default function Advertencia(props: { mensaje: string, showIcon: boolean }) {
    return (
        <div className='rounded'
             style={{
                 background: '#e3f2fb',
                 padding: '20px',
                 marginBottom: '4px',
                 border:"1px solid #d9dbde"
             }}>
            <p style={{fontSize: '0.9em',color: '#3c3c3c'}}>
                {props.showIcon && <span className='fs-6 fi bi-info-circle-fill'/>} {props.mensaje}
            </p>
        </div>
    )
}