import React, {useState} from "react";
import SubmenuItem from "./SubmenuItem";
import {isAdmin} from "../../utils";

export default function Sidebar(
    props: {
        selectedMenuItem: string,
        onChangeSelectedMenuItem: (e: string) => void
    }
    ) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };


    return (
        <>
            <div id="sidebar-container"
                 className={`l-navbar ${isOpen ? 'sidebar-expanded col-sm-2' : 'sidebar-collapsed'} bg-dark`}>
                <ul className="list-group" style={{border: '0px'}}>
                    <SubmenuItem icon='bi bi-speedometer2' text={'Estadísticas'} isOpen={isOpen}
                                 selected={props.selectedMenuItem === 'charts'} onSelect={() => {
                        props.onChangeSelectedMenuItem('charts');
                    }}/>

                    <SubmenuItem icon='bi bi-newspaper' text={'Nueva cotización'} isOpen={isOpen}
                                 selected={props.selectedMenuItem === 'cotizacion'} onSelect={() => {
                        props.onChangeSelectedMenuItem('cotizacion');
                    }}/>

                    <SubmenuItem icon='bi bi-file-spreadsheet' text={'Cotizaciones'} isOpen={isOpen}
                                 selected={props.selectedMenuItem === 'consultas'} onSelect={() => {
                        props.onChangeSelectedMenuItem('consultas');
                    }}/>

                    <SubmenuItem icon='bi bi-list-check' text="Servicios" isOpen={isOpen}
                                 selected={props.selectedMenuItem === 'servicios'} onSelect={() => {
                        props.onChangeSelectedMenuItem('servicios');
                    }}/>
                    <SubmenuItem icon='bi bi-list' text="Catálogo de servicios" isOpen={isOpen}
                                 selected={props.selectedMenuItem === 'confplan'} onSelect={() => {
                        props.onChangeSelectedMenuItem('confplan');
                    }}/>
                    {
                        isAdmin() && <SubmenuItem icon='bi bi-people-fill' text="Usuarios" isOpen={isOpen}
                                                  selected={props.selectedMenuItem === 'usuarios'} onSelect={() => {
                            props.onChangeSelectedMenuItem('usuarios');
                        }}/>
                    }

                    {
                        isAdmin() && <SubmenuItem icon='bi bi-dropbox' text="Cuenta de dropbox" isOpen={isOpen}
                                                  selected={props.selectedMenuItem === 'dropbox'} onSelect={() => {
                            props.onChangeSelectedMenuItem('dropbox');
                        }}/>
                    }

                    {
                        isAdmin() && <SubmenuItem icon='bi bi-envelope-fill' text="Correos" isOpen={isOpen}
                                                  selected={props.selectedMenuItem === 'eventos'} onSelect={() => {
                            props.onChangeSelectedMenuItem('eventos');
                        }}/>
                    }
                </ul>

                <div className='d-flex justify-content-end'>
                    <a href='#'
                       className="btn btn-dark"
                       onClick={toggleSidebar}
                       data-bs-toggle="collapse"
                       data-bs-target="#sidebar-container"
                       aria-expanded={isOpen}
                       aria-controls="sidebar"
                    >
                        <i className={isOpen?'bi bi-chevron-double-left':'bi-chevron-double-right'}></i>
                    </a>
                </div>
            </div>


        </>
    )
}
