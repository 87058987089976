import axios from "axios";
import {getCsrfToken} from "./conem-services";
import {Response} from "../types/response.type";
import {ClienteDetails} from "../types/cliente.details.type";

export async function getClienteByEmail(email: string, prefijoFolio: string):Promise<Response<ClienteDetails>> {
    const {data} = await axios.get(`/api/servicios/clientes/${email}/${prefijoFolio}`, {
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': await getCsrfToken(),
        },
    });
    return data;
}
