import React from "react";
import {NamedItem} from "../../types/servicio";


export default function DiasSemana(props: {
    workingDays: NamedItem[],
    disabled:boolean,
    change: (index: number, value: boolean) => void
}) {

    return (
        <div>
            {
                props.workingDays.map((day: NamedItem, index: number) => {
                    return <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               checked={day.enabled}
                               disabled={props.disabled}
                               id="solicitudProrroga"
                               onChange={(e) => props.change(index, e.target.checked)}/>
                        <label className="form-check-label" htmlFor="solicitudProrroga">
                            {day.name}
                        </label>
                    </div>
                })
            }
        </div>
    )
}