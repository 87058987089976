import {Button, Container, Form, Row} from "react-bootstrap";
import CheckBoxFilter from "../common/CheckBoxFilter";
import React, {useState} from "react";
import {FiltroServicio} from "../../types/servicio";
import {formatoFechaSimple, isAdmin} from "../../utils";
import DatePicker from "react-datepicker";

export default function FiltroServicios(props: { aplicarFiltro: (e: FiltroServicio) => void }) {

    const [fecha, setFecha] = useState<Date | null>(null);
    const [nombreCliente, setNombreCliente] = useState<string>('');
    const [folio, setFolio] = useState<string>('');
    const [documentosFaltantes, setDocumentosFaltantes] = useState<boolean>(false);
    const [sinFechaInspeccion, setSinFechaInspeccion] = useState<boolean>(false);
    const [sinFechaCapacitacion, setSinFechaCapacitacion] = useState<boolean>(false);
    const [sinCoodinadorAsignado, setSinCoodinadorAsignado] = useState<boolean>(false);
    const [sinOperadorAsignado, setSinOperadorAsignado] = useState<boolean>(false);
    const [sinCalidadAsignado, setSinCalidadAsignado] = useState<boolean>(false);
    const [sinInstructorAsignado, setSinInstructorAsignado] = useState<boolean>(false);
    const [conObservaciones, setConObservaciones] = useState<boolean>(false);
    const [enProgreso, setEnProgreso] = useState<boolean>(false);
    const [porIniciar, setPorIniciar] = useState<boolean>(false);
    const [finalizado, setFinalizado] = useState<boolean>(false);
    const [pendientePago, setPendientePago] = useState<boolean>(false);


    return (
        <>
            <div className='filter'>
                <Button variant='outline-dark' size='sm'>
                    <i className="bi bi-funnel-fill"/> Filtrar
                </Button>
                <div className='filter-content rounded'>
                    <Row>
                            <span className="text-muted"
                                  style={{fontSize: "0.8rem", marginLeft: "0px"}}>Fecha de aceptación</span>
                    </Row>
                    <Container>
                        <Row>
                            <DatePicker dateFormat='dd/MM/yyyy'
                                className="text-muted rounded filter-date" selected={fecha}
                                locale="es"
                                isClearable
                                onChange={(date: Date) => {
                                    setFecha(date);
                                }}/>
                        </Row>
                    </Container>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Nombre del cliente</span>
                    </Row>
                    <Container>
                        <Row className="row-cols-auto">
                            <Form.Control type="text" style={{width: "300px"}} value={nombreCliente}
                                          maxLength={300}
                                          onChange={(e) => {
                                              setNombreCliente(e.target.value)
                                          }}>
                            </Form.Control>
                        </Row>
                    </Container>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Folio</span>
                    </Row>
                    <Container>
                        <Row className="row-cols-auto">
                            <Form.Control type="text" style={{width: "300px"}} value={folio}
                                          maxLength={100}
                                          onChange={(e) => {
                                              setFolio(e.target.value)
                                          }}>
                            </Form.Control>
                        </Row>
                    </Container>
                    <hr/>
                    <Row>
                        <span className="text-muted" style={{fontSize: "0.8rem"}}>Status del servicio</span>
                    </Row>
                    <Container>
                        <Row>
                            <CheckBoxFilter label="Con documentos faltantes"
                                            onChange={(checked: boolean) => {
                                             setDocumentosFaltantes(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Sin fecha de inspección"
                                            onChange={(checked: boolean) => {
                                             setSinFechaInspeccion(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Sin fecha de capacitación"
                                            onChange={(checked: boolean) => {
                                             setSinFechaCapacitacion(checked);
                                         }}/>
                        </Row>
                        {
                            isAdmin() && <Row>
                                <CheckBoxFilter label="Sin coordinador asignado" id="pendienteCoordinador"
                                                onChange={(checked: boolean) => {
                                                 setSinCoodinadorAsignado(checked);
                                             }}/>
                            </Row>
                        }

                        <Row>
                            <CheckBoxFilter label="Sin operativo asignado" id="pendienteOperativo"
                                            onChange={(checked: boolean) => {
                                             setSinOperadorAsignado(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Sin personal de calidad asignado" id="pendienteOperativo"
                                            onChange={(checked: boolean) => {
                                             setSinCalidadAsignado(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Sin instructor asignado" id="pendienteOperativo"
                                            onChange={(checked: boolean) => {
                                             setSinInstructorAsignado(checked);
                                         }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Con observaciones" id="observaciones" onChange={(checked: boolean) => {
                                setConObservaciones(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Por Iniciar" id="por_iniciar" onChange={(checked: boolean) => {
                                setPorIniciar(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="En progreso" id="progreso" onChange={(checked: boolean) => {
                                setEnProgreso(checked);
                            }}/>
                        </Row>
                        <Row>
                            <CheckBoxFilter label="Finalizado" id="finalizado" onChange={(checked: boolean) => {
                                setFinalizado(checked);
                            }}/>
                        </Row>
                        {
                            isAdmin() &&
                            <Row>
                                <CheckBoxFilter label="Pendiente de pago" id="pendientePago"
                                                onChange={(checked: boolean) => {
                                                 setPendientePago(checked);
                                             }}/>
                            </Row>
                        }

                    </Container>
                    <hr/>
                    <div className='d-flex justify-content-center'>
                        <Button variant="primary" size='sm' style={{width: '150px'}} onClick={() => {
                            let newFiltro: FiltroServicio = {
                                nombreCliente: nombreCliente,
                                documentosFaltantes: documentosFaltantes,
                                fechaInspeccion: sinFechaInspeccion,
                                fechaCapacitacion: sinFechaCapacitacion,
                                coordinadorAsignado: sinCoodinadorAsignado,
                                operadorAsignado: sinOperadorAsignado,
                                calidadAsignado: sinCalidadAsignado,
                                instructorAsignado: sinInstructorAsignado,
                                observaciones: conObservaciones,
                                porIniciar: porIniciar,
                                progreso: enProgreso,
                                finalizado: finalizado,
                                pendientePago: pendientePago,
                                currentPage: 0,
                                rowsPerPage: 10
                            };
                            if (fecha) {
                                newFiltro = {...newFiltro, fechaAceptacion: formatoFechaSimple(fecha.toString())}
                            }
                            props.aplicarFiltro(newFiltro);
                        }}>
                            Aplicar filtro
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}