import {useState} from "react";

interface Input{
    type:string,
    value: string,
    maxLength: number,
    onChange: (e:any)=>void,
    onBlur: ()=>void,
    disabled: boolean,
    required: boolean,
    isInvalid: boolean,
    error: string|null,
    setValue: (value: string) => void,
}

export function useFormInput(
    type:string='text',
    initialValue: string | null | undefined,
    maxLength: number,
    msgError: string | null,
    empty: boolean = false,
    required: boolean = false,
    disabled: boolean = false,
    funcValidation?: ((value: string) => boolean)|null,
    onBlur?:(value:string)=>void,):Input {

    const [value, setValue] = useState(initialValue ? initialValue : '');
    const [valid, setValid] = useState(true);
    const [error, setError] = useState<string | null>(null);

    // @ts-ignore
    function handleChange(e) {
        setValue(e.target.value);
    }

    function handleBlur() {
        if (!empty && (!value || value.trim() === '')) {
            setValid(false);
            setError(msgError)
        } else {
            if (funcValidation) {
                if (funcValidation(value)) {
                    setValid(true);
                    setError(null);
                    if (onBlur){
                        onBlur(value);
                    }
                } else {
                    setValid(false);
                    setError(msgError);
                }
            } else {
                setValid(true);
                setError(null);
                if (onBlur){
                    onBlur(value);
                }
            }
        }
    }

    return {
        type:type,
        value: value,
        maxLength: maxLength,
        onChange: handleChange,
        onBlur: handleBlur,
        disabled: disabled,
        required: required,
        isInvalid: !valid,
        error: error,
        setValue: (value: string) => {
            setValue(value)
            handleBlur();
        }
    };
}