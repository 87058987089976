import axios from "axios";

 async function getCsrfToken() {
    try {
        const response = await fetch('/api/csrf')
        if (response?.ok) {
            const payload = await response.json()
            return payload.token
        } else {
            console.log(`HTTP Response Code: ${response?.status}`)
        }
    } catch (err) {
        console.log('There was an error getting the token', err);
    }
}

export async function eliminarObservaciones(servicioId: string, actividadId: string, observacionId: string) {
    const {data} = await axios.post(`/api/servicios/${servicioId}/actividades/${actividadId}/observacion/${observacionId}/delete`, {},{
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getCsrfToken(),
            },
        }
    )
    return data;
}