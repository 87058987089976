import {Container} from "react-bootstrap";
import React from "react";
import './RoundedDashedBox.css';

export default function RoundedDashedBox(props: { label?: string ,sm?:boolean}) {
    return (
        <Container className="rounded text-xl-center dashed-box d-flex justify-content-center align-items-center">
            {
                props.label && <div>
                    <label className={`${props.sm?'fs-6':'fs-3'} fw-bold`} style={{color: '#d0cece'}}>{props.label}</label>
                </div>
            }
        </Container>
    )
}