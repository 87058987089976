import {Actividad, Servicio, Subservicio} from "../types/servicio";


export const OK = 200;
export const NOT_FOUND = 404;

export const SERVICIO_STATUS_EN_PROGRESO = 'en_progreso';
export const SERVICIO_STATUS_FINALIZADO = 'finalizado';
export const ACTIVIDAD_STATUS_EN_PROGRESO = 'en_progreso';
export const ACTIVIDAD_STATUS_FINALIZADO = 'finalizado';


export const FILE_MAX_SIZE=100 * 1024 * 1024;

const date = new Date();
const year = date.getFullYear();
const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
const month = months[date.getMonth()];

export const FOLIO_PREFIX = month + "" + year;

export const timeStamp = () => {
    const date = new Date();

    return `${String(date.getDate()).padStart(2, '0')}${String(date.getMonth() + 1).padStart(2, '0')}${date.getFullYear()}${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`
}

export const getPorcentajeAvance = (servicio: Servicio) => {
    if (servicio.status === 'finalizado') return 100;
    let p = 0;
    p += (servicio.fechaCapacitacion ? 1 : 0);
    p += (servicio.fechaInspeccion ? 1 : 0);
    p += (servicio.coordinador ? 1 : 0);
    p += (servicio.calidad ? 1 : 0);
    p += (servicio.operador ? 1 : 0);
    p += (servicio.instructor ? 1 : 0);
    const incremento = 94 / servicio.actividades.length;
    p += servicio.actividades.reduce((acumm, currentValue: Actividad) => {
        return (acumm + (currentValue.status === 'finalizado' ? incremento : 0));
    }, 0)
    return Math.ceil(p);
}

export const formatoMoneda = (value: any) => {
    if (value)
        return `$${(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
    return '$ 0.00'
}

export const getAnioActual = () => {
    return new Date().getFullYear();
}

/**
 * Devuelve el mes actual basado en indice 0
 * Enero=0, Febrero=1,...Diciembre=11
 * */
export const getMesActual = () => {
    return new Date().getMonth();
}

export const getBimestreActual = ()=> {
    const mesActual=getMesActual();
    if (mesActual<2) {return 1}
    if (mesActual<4) {return 2}
    if (mesActual<6) {return 3}
    if (mesActual<8) {return 4}
    if (mesActual<10) {return 5}
    if (mesActual<12) {return 6}
    return 1;
}

export const dateToSimpleFormat = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T01:00:00.000-06:00`
}

export const formatoFechaTexto = (strDate: string | null | undefined) => {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const date = toDate(strDate);
    if (date) {
        return `${String(date.getDate()).padStart(2, '0')} de ${meses[date.getMonth()]} de ${date.getFullYear()}`
    }
    return '';
}

export const formatoNumeroFolio = (numeroFolio: number) => {
    return String(numeroFolio).padStart(3, '0')
}

/**
 * convierte una cadena con formato de fecha a un objeto de tipo Date
 * */
export const toDate = (strDate: string | null | undefined) => {
    return strDate ? new Date(Date.parse(strDate)) : null;
}

export const formatoFechaSimple = (strDate: string | null | undefined) => {
    const date = toDate(strDate);
    if (date) {
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`
    }
    return '';
}

export const formatoFechaTiempo = (strDate: string | null) => {
    const date = toDate(strDate);
    if (date) {
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    }
    return '';
}

export const getUsuarioSesion = () => {
    const jsonUser = sessionStorage.getItem('user');
    return jsonUser ? JSON.parse(jsonUser) : null;
}

export const getUsuarioSesionNombreCompleto = () => {
    const user = getUsuarioSesion();
    if (user) {
        return user.fullName;
    }
    return null;
}

export const getUsuarioSesionUsername = () => {
    const user = getUsuarioSesion();
    if (user) {
        return user.username;
    }
    return null;
}

export const isAdmin = () => {
    const user = getUsuarioSesion();
    if (user?.authorities.length > 0) {
        return user.authorities.includes('ROLE_ADMIN');
    }
    return false;
}

export const isCoordinador = () => {
    const user = getUsuarioSesion();
    if (user?.authorities.length > 0) {
        return user.authorities.includes('ROLE_MANAGER');
    }
    return false;
}

export const isVentas = () => {
    const user = getUsuarioSesion();
    if (user?.authorities.length > 0) {
        return user.authorities.includes('ROLE_VENTAS');
    }
    return false;
}

export const isCalidad = () => {
    const user = getUsuarioSesion();
    if (user?.authorities.length > 0) {
        return user.authorities.includes('ROLE_QUALITY_ASSURANCE');
    }
    return false;
}

export const isOperativo = () => {
    const user = getUsuarioSesion();
    if (user?.authorities.length > 0) {
        return user.authorities.includes('ROLE_OPERATOR');
    }
    return false;
}

export const isCliente = () => {
    const user = getUsuarioSesion();
    if (user?.authorities.length > 0) {
        return user.authorities.includes('ROLE_CLIENT');
    }
    return false;
}

export const isRequeridoCambioContrasenia = () => {
    const user = getUsuarioSesion();
    return user?.tipo === 1;
}

export const getSubtotalSubservicio = (subservicio: Subservicio) => {
    return getPrecioSubservicio(subservicio) - getDescuentoSubservicio(subservicio);
}

export const getSumaSubtotalSubservicios = (servicios: Subservicio[]) => {
    return servicios.reduce((accumulator, currentValue: Subservicio) => {
        return accumulator + getSubtotalSubservicio(currentValue);
    }, 0);
}

export function byteConverter(bytes: number, decimals: number, only?: any) {
    const K_UNIT = 1000;
    const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    if (bytes === 0) return "0 Byte";
    if (only === "MB") return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + " MB";
    let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
    let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];
    return resp;
}

export const getPerfilLabel = () => {
    if (isAdmin()) return "Administrador";
    if (isVentas()) return "Ventas";
    if (isCoordinador()) return "Coordinador";
    if (isOperativo()) return "Operador";
    if (isCalidad()) return "Calidad";
    if (isCliente()) return "Cliente";
    return "";
}

export const getTipoLabel = (tipo: string) => {
    if (tipo === 'cotizacion') return 'Cotización';
    if (tipo === 'servicio') return 'Servicio';
    return '';
}

export const getStatusLabel = (status: string) => {
    if (status === 'guardado') return 'Guardado';
    if (status === 'enviado') return 'Enviado';
    if (status === 'rechazado') return 'Rechazado';
    if (status === 'expirado') return 'Expirado';
    if (status === 'cancelado') return 'Cancelado';
    if (status === 'por_iniciar') return 'Por iniciar';
    if (status === 'en_progreso') return 'En progreso';
    if (status === 'finalizado') return 'Finalizado';
    return '';
}

export const getPrecioSubservicio = (subservicio: Subservicio) => {
    if (subservicio.precio < 0) return 0;
    return (subservicio.precio);
}

export const getDescuentoSubservicio = (subservicio: Subservicio) => {
    if (subservicio.porcentajeDescuento < 0) return 0;
    return (getPrecioSubservicio(subservicio) * (subservicio.porcentajeDescuento / 100.0));
}

export const getSumaPrecioSubservicios = (subservicios: Subservicio[]) => {
    return subservicios.reduce((accumulator, currentValue: Subservicio) => {
        return accumulator + getPrecioSubservicio(currentValue);
    }, 0);
}

export const getSumaDescuentoSubservicios = (subservicios: Subservicio[]) => {
    const v = subservicios.reduce((accumulator, currentValue: Subservicio) => {
        return accumulator + getDescuentoSubservicio(currentValue);
    }, 0);
    return v > 0 ? v : null;
}

export const getSumaTotalServicios = (servicio: Servicio[]) => {
    return servicio.reduce((accumulator, currentValue: Servicio) => {
        return accumulator + currentValue.total;
    }, 0);
}

export const isCorreoValido = (str: string) => {
    if (!str) return false;
    return (str.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g) !== null)
}

export const isTelefonoValido = (str: string) => {
    if (str) {
        return str.match(/[0-9]{10}/g) != null
    }
    return false;
}

export const isContraseniaValida = (str: string) => {
    if (!str) return false;
    return (str.length >= 8 && str.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,}$/) !== null)
}

export const isURLValida = (str: string) => {
    if (!str) return false;
    const pattern = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );
    return pattern.test(str);
}

export const truncarTexto = (value: string|null, maxLength: number, puntosSuspensivos: boolean = true) => {
    if (!value)return ''
    return value && value.length > maxLength ? value.slice(0, maxLength) + (puntosSuspensivos ? '...' : '') : value;
}

export const isEnableDownloadDocuments = () => {
    return (isAdmin() || isVentas() || isCalidad() || isCoordinador() || isOperativo() || isCliente())
}