import * as React from 'react';
import {useEffect, useState} from 'react';
import {Accordion} from "react-bootstrap";
import {ServicePagination, Servicio} from "../../types/servicio";
import CotizacionItem from "./CotizacionItem";

export default function TablaCotizaciones(props: {
    pagination: ServicePagination,
    editar: (cotizacion: Servicio) => void,
    actualizar: () => void,
    onUpdate: (e: Servicio) => void;
}) {

    const [indexActiveTab, setIndexActiveTab] = useState<number>(-1);

    const [formEditionIndex, setFormEditionIndex] = useState<number>(-1);

    useEffect(() => {
        setIndexActiveTab(-1);
    }, [props.pagination]);


    return (
        <>
            <Accordion defaultActiveKey={`${indexActiveTab}`}>
                {
                    props.pagination.items.map((cotizacion: Servicio, i: number) => {
                        return <CotizacionItem
                            editable={i === formEditionIndex}
                            cotizacion={cotizacion}
                            selectedIndex={indexActiveTab}
                            index={i}
                            actualizar={props.actualizar}
                            onSelect={(index: number) => {
                                setIndexActiveTab(index);
                            }}
                            onEdit={(index: number) => {
                                setFormEditionIndex(index);
                            }}
                            onUpdate={props.onUpdate}
                        />

                    })
                }

            </Accordion>


        </>

    );
}