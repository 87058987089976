import {byteConverter, FILE_MAX_SIZE, formatoFechaTiempo, getUsuarioSesion, truncarTexto} from "../../utils";
import React, {useEffect, useRef, useState} from "react";
import cloneDeep from "lodash.clonedeep";
import './FileSelector.css';

export default function FileSelector(
    props: {
        multiple?: boolean,
        text?: string,
        onSelect: (file: any) => void }
) {

    const [files, setFiles] = useState<any>([])

    useEffect(()=>{
        // @ts-ignore
        if (hiddenFileInputRef.current.value){
            // @ts-ignore
            hiddenFileInputRef.current.value = "";
        }
        setFiles([]);
    },[])

    const clickSelectFiles = () => {
        if (hiddenFileInputRef && hiddenFileInputRef.current) {
            // @ts-ignore
            hiddenFileInputRef.current.click();
        }
    }

    const hiddenFileInputRef = useRef(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setFiles([]);
            for (let i = 0; i < e.target.files.length; i++) {
                const newFile = e.target.files.item(i);
                if (newFile && (newFile.size <= 0 || newFile.size >= FILE_MAX_SIZE)) {
                    setFiles([]);
                    // @ts-ignore
                    e.target.value=null
                    props.onSelect(null);
                    //mostrarMensajeError("El tamaño mínimo de archivo es 1 byte y menor a 100 MB");
                    break;
                } else {
                    props.onSelect(newFile);
                    if (props.multiple) setFiles([...files, newFile]);
                    else setFiles([newFile])
                }
            }
        }
        // @ts-ignore
        e.target.value=null
    }

    function FileRow(file: any, index: number) {
        return (
            <li className="list-group-item">
                <div className='container rounded file-selector-item'>
                    <div className='row'>
                        <div className='col-sm'>
                            <div className='d-inline-flex'>
                                <div style={{marginRight:'10px'}}>
                                    <i className='fs-1 bi bi-file-earmark'/>
                                </div>
                                <div>
                                    <div className='d-flex align-items-center file-selector-item'>{file.name}</div>
                                    <div
                                        className='file-selector-item-details'>Tamaño: {byteConverter(file.size, 2)}</div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='rounded col-sm-1 d-flex align-items-center justify-content-center'>
                            <a onClick={() => {
                                let newFiles = cloneDeep(files);
                                newFiles.splice(index, 1);
                                setFiles(newFiles);
                            }}>Eliminar</a>

                        </div>
                    </div>

                </div>
            </li>
        )
    }

    return (<>
            <input id="file" type="file" multiple={props.multiple} ref={hiddenFileInputRef} style={{display: 'none'}}
                   onChange={handleFileChange}/>
            <div className="card" style={{borderStyle:'dashed',borderWidth:'1px',borderColor:'#747373'}}>
                {
                    files.length === 0 &&
                    <div className="card-header">
                    <div className='align-content-center align-items-center justify-content-center rounded' style={{color:'#565555'}}>
                        <a onClick={clickSelectFiles}>
                            <i className='fs-5 bi bi-plus-square-fill'></i>
                            <span style={{marginLeft:'5px'}}>{props.text ? props.text : 'Agregar documento'}</span>
                        </a>
                    </div>
                </div>
                }
                <ul className="list-group list-group-flush">
                    {
                        files?.map((item: any, index: any) => {
                            return FileRow(item, index);
                        })
                    }
                </ul>
            </div>


        </>
    )
}