import {Button, Col, Container, Dropdown, DropdownButton, Form, Modal, Row} from "react-bootstrap";
import {omit} from "lodash";
import {
    actualizarUsuario,
    crearUsuario,
    listAllCoordinadores,
    listGroups,
    roleText
} from "../../services/conem-services";
import {Grupo, Usuario} from "../../types/servicio";
import React, {useEffect, useState} from "react";
import {isCorreoValido, OK} from "../../utils";

export default function UsuarioModal(props: {
    show: boolean,
    usuario: Usuario,
    onClose: () => void,
    onChange: (e: Usuario) => void,
}) {

    const [nombreCompleto, setNombreCompleto] = useState<string>('');
    const [correo, setCorreo] = useState<string>('');
    const [rol, setRol] = useState<string>('');

    const [correoConfirmacion, setCorreoConfirmacion] = useState<string>('');

    const [errores, setErrores] = useState<any>({});
    const [showCoordinadores, setShowCoordinadores] = useState<boolean>(false);
    const [grupoSeleccionado, setGrupoSeleccionado] = useState<Grupo | null>(null);
    const [coordinador, setCoordinador] = useState<Usuario | null>(null);
    const [enabled, setEnabled] = useState<boolean>(false);

    const [coordinadores, setCoordinadores] = useState<Usuario[] | null>(null);
    const [grupos, setGrupos] = useState<Grupo[] | null>(null);
    const [respuestaError, setRespuestaError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(false);
        setRespuestaError('');
        setEnabled(props.usuario.enabled);
        setErrores({});
        setNombreCompleto(props.usuario.nombreCompleto);
        setCorreo(props.usuario.correo);
        setRol(props.usuario.role);
        setCorreoConfirmacion('');
        if (props.usuario.grupo) {
            setGrupoSeleccionado(props.usuario.grupo);
        } else {
            setGrupoSeleccionado(null)
        }
        if (props.usuario.coordinador) {
            setCoordinador(props.usuario.coordinador);
        } else {
            setCoordinador(null);
        }
        listGroups().then(data => {
            setGrupos(data);
        }).catch(err => setRespuestaError("No se pudo cargar la lista de grupos existentes"))
    }, [props.usuario]);

    useEffect(() => {

        //cuando se desea crear un usuario operativo
        //al cambiar el grupo se deben cargar los coordinadores correspondientes a
        //ese grupo
        if (rol === 'ROLE_OPERATOR' && grupoSeleccionado) {
            setCoordinador(null);
            listAllCoordinadores().then(resp => {
                if (resp.statusCode===OK){
                    setCoordinadores(
                        resp.data.filter((usuario: Usuario) => (usuario.grupo?.id === grupoSeleccionado.id))
                    );
                }
            }).catch(err => setRespuestaError("No se pudo cargar la lista de coordinadores existentes"));

        }

    }, [grupoSeleccionado]);

    useEffect(() => {
        if (rol) {
            let newErrors = omit(errores, 'grupo');
            newErrors = omit(newErrors, 'coordinador');
            setErrores(newErrors);
            setShowCoordinadores(rol === 'ROLE_OPERATOR');
        }
    }, [rol])

    const isValid = (usuario: Usuario, confirmEmail: boolean = true) => {
        let errores = {}
        if (!usuario.nombreCompleto || usuario.nombreCompleto.length > 200) {
            errores = {
                ...errores,
                nombreCompleto: "El nombre completo del usuario es requerido y no debe ser mayor a 200 caracteres"
            }
        }
        if (!usuario.correo || !isCorreoValido(usuario.correo) || usuario.correo.length > 100) {
            errores = {...errores, correo: "Debe ingresar un correo válido no mayor a 100 caracteres"}
        }
        if (confirmEmail && (!isCorreoValido(correoConfirmacion)
            || correoConfirmacion !== correo)) {
            errores = {
                ...errores,
                correoConfirmacion: "El correo de confirmación no coincide. Debe ingresar un correo válido no mayor a 100 caracteres"
            }
        }
        if (usuario.role === 'ROLE_OPERATOR' && !usuario.coordinador) {
            errores = {...errores, coordinador: "Debe asignar un coordinador al usuario"}
        }
        if ((usuario.role !== 'ROLE_CLIENTE' && usuario.role !== 'ROLE_ADMIN')
            && usuario.grupo === null) {
            errores = {...errores, grupo: "Debe especificar un grupo para el usuario"}
        }
        setErrores(errores);
        return Object.keys(errores).length === 0
    }

    const saveUser = (user: Usuario) => {
        setRespuestaError('');
        if (isValid(user, false)) {
            setLoading(true);
            actualizarUsuario(user).then(
                resp => {
                    if (resp.statusCode === OK) {
                        props.onChange(resp.data);
                    } else {
                        setRespuestaError(resp.message);
                    }
                }
            ).catch(err => {
                setRespuestaError("Lo sentimos ha ocurrido un error al intentar actualizar el usuario");
            }).finally(
                () => {
                    setLoading(false);
                }
            );
        } else {
            console.log(errores)
        }
    }

    const createUser = (newUser: Usuario) => {
        setRespuestaError('');
        if (isValid(newUser)) {
            setLoading(true);
            crearUsuario(newUser).then(
                resp => {
                    if (resp.statusCode === OK) {
                        props.onChange(resp.data);
                    } else {
                        setRespuestaError(resp.message);
                    }
                }
            ).catch(err => {
                setRespuestaError("Lo sentimos ha ocurrido un error al intentar crear el usuario");
            }).finally(
                () => {
                    setLoading(false);
                }
            );
        }
    }

    return (
        <Modal size="lg" className="modal-dialog-centered"
               show={props.show}>
            <Modal.Header className='modal-header'>
                <Modal.Title className="fs-6">
                    {props.usuario.id ? 'Editar usuario' : 'Nuevo usuario'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form.Group as={Row} className='mb-3'>
                        <Form.Label column sm='4'>
                            <span>Nombre completo <span style={{color: 'red'}}>*</span></span>
                        </Form.Label>
                        <Col sm='8'>
                            <Form.Control value={nombreCompleto} maxLength={200}
                                          isInvalid={!!errores.nombreCompleto}
                                          onChange={(e) => {
                                              setNombreCompleto(e.target.value);
                                          }}
                                          onBlur={() => {
                                              if (!nombreCompleto || nombreCompleto.trim() === '') {
                                                  setErrores({
                                                      ...errores,
                                                      nombreCompleto: 'El nombre completo del usuario es requerido y no debe ser mayor a 200 caracteres'
                                                  })
                                              } else {
                                                  const newErrores = omit(errores, 'nombreCompleto');
                                                  setErrores(newErrores);
                                              }
                                          }}/>
                            <Form.Control.Feedback type='invalid'>
                                {errores.nombreCompleto}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className='mb-3' controlId='formServiceName'>
                        <Form.Label column sm='4'>
                            <span>Correo electrónico <span style={{color: 'red'}}>*</span></span>
                        </Form.Label>
                        <Col sm='8'>
                            <Form.Control type="email" maxLength={100} value={correo}
                                          isInvalid={!!errores.correo}
                                          onChange={(e) => {
                                              const newValue=e.target.value.trim();
                                              setCorreo(newValue);
                                          }}
                                          onBlur={() => {
                                              if (!isCorreoValido(correo)) {
                                                  setErrores({
                                                      ...errores,
                                                      correo: 'Debe ingresar un correo válido no mayor a 100 caracteres'
                                                  })
                                              } else {
                                                  let newErrores = omit(errores, 'correo');
                                                  if (correoConfirmacion && (!isCorreoValido(correoConfirmacion)
                                                      || correoConfirmacion !== correo)) {
                                                      newErrores = {
                                                          ...newErrores,
                                                          correoConfirmacion: 'El correo de confirmación no coincide. Debe ingresar un correo válido no mayor a 100 caracteres'
                                                      }
                                                  } else if (correo === correoConfirmacion) {
                                                      newErrores = omit(newErrores, 'correoConfirmacion');
                                                  }
                                                  setErrores(newErrores)
                                              }
                                          }}
                            />
                            <Form.Control.Feedback type='invalid'>{errores.correo}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    {
                        !props.usuario.id &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='4'>
                                <span>Confirmar correo electrónico <span style={{color: 'red'}}>*</span></span>
                            </Form.Label>
                            <Col sm='8'>
                                <Form.Control type="email" maxLength={100} value={correoConfirmacion}
                                              isInvalid={!!errores.correoConfirmacion}
                                              onChange={(e) => {
                                                  setCorreoConfirmacion(e.target.value.trim());
                                              }}
                                              onBlur={() => {
                                                  if (!isCorreoValido(correoConfirmacion)
                                                      || correoConfirmacion !== correo) {
                                                      setErrores({
                                                          ...errores,
                                                          correoConfirmacion: 'El correo de confirmación no coincide. Debe ingresar un correo válido no mayor a 100 caracteres'
                                                      })
                                                  } else {
                                                      const newErrores = omit(errores, 'correoConfirmacion');
                                                      setErrores(newErrores)
                                                  }
                                              }}
                                />
                                <Form.Control.Feedback
                                    type='invalid'>{errores.correoConfirmacion}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    }
                    <Form.Group as={Row} className='mb-3'>
                        <Form.Label column sm='4'>
                            <span>Rol <span style={{color: 'red'}}>*</span></span>
                        </Form.Label>
                        <Col sm='8'>
                            <DropdownButton size="sm" title={roleText(rol)}>
                                <Dropdown.Item eventKey="1" onClick={() => {
                                    setRol('ROLE_ADMIN');
                                }}>Administrador</Dropdown.Item>
                                <Dropdown.Item eventKey="1" onClick={() => {
                                    setRol('ROLE_VENTAS');
                                }}>Ventas</Dropdown.Item>
                                <Dropdown.Item eventKey="2" onClick={() => {
                                    setRol('ROLE_QUALITY_ASSURANCE');
                                }}>Calidad</Dropdown.Item>
                                <Dropdown.Item eventKey="3" onClick={() => {
                                    setRol('ROLE_MANAGER');
                                }}>Coordinador</Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={() => {
                                    setRol('ROLE_OPERATOR');

                                }}>Operativo</Dropdown.Item>
                                <Dropdown.Item eventKey="5" onClick={() => {
                                    setRol('ROLE_CLIENT');
                                }}>Cliente</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        {
                            errores.rol &&
                            <span className='errores-validacion'>{errores.rol}</span>
                        }
                    </Form.Group>
                    {
                        (rol !== 'ROLE_ADMIN' && rol !== 'ROLE_CLIENT') &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='4'>
                                <span>Grupo <span style={{color: 'red'}}>*</span></span>
                            </Form.Label>
                            <Col sm='8'>
                                <DropdownButton size="sm"
                                                title={grupoSeleccionado ? grupoSeleccionado.nombre : 'Seleccione un grupo'}>
                                    {
                                        grupos?.map((grupo: Grupo, index: number) => {
                                            return <Dropdown.Item eventKey={`${index}`} onClick={() => {
                                                setGrupoSeleccionado(grupo);
                                                setErrores(omit(errores, 'grupo'))
                                            }}>{grupo.nombre}</Dropdown.Item>
                                        })
                                    }
                                </DropdownButton>
                                {
                                    errores.grupo &&
                                    <span className='errores-validacion'>{errores.grupo}</span>
                                }
                            </Col>
                        </Form.Group>
                    }
                    {
                        rol === 'ROLE_OPERATOR' &&
                        <Form.Group as={Row} className='mb-3'>
                            <Form.Label column sm='4'>
                                <span>Coordinador <span style={{color: 'red'}}>*</span></span>
                            </Form.Label>
                            <Col sm='8'>
                                <DropdownButton size="sm"
                                                title={coordinador ? coordinador.nombreCompleto : 'Seleccione un coordinador'}>
                                    {
                                        coordinadores?.map((coordinador: Usuario, index: number) => {
                                            return <Dropdown.Item eventKey="1" onClick={() => {
                                                setCoordinador(coordinador);
                                                setErrores(omit(errores, 'coordinador'));
                                            }}>{coordinador.nombreCompleto}</Dropdown.Item>
                                        })
                                    }
                                </DropdownButton>
                                {
                                    errores.coordinador &&
                                    <span className='errores-validacion'>{errores.coordinador}</span>
                                }
                            </Col>
                        </Form.Group>
                    }
                </Container>
                <Container>
                    {respuestaError && <span className='errores-validacion'>{respuestaError}</span>}
                </Container>
                <Container style={{fontSize: '0.8rem'}}>
                    {!props.usuario.id &&
                        <>* La contraseña se generará de forma automática y se enviará al correo del usuario.</>}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-end'>
                    <Button size='sm' variant='danger' onClick={props.onClose} disabled={loading}>
                        Cancelar
                    </Button>
                    <Button size='sm' variant='dark'
                            disabled={loading || Object.keys(errores).length > 0}
                            onClick={() => {
                                if (props.usuario.id) {
                                    const editUsuario: Usuario = {
                                        tipo: props.usuario.tipo,
                                        id: props.usuario.id,
                                        correo: correo,
                                        enabled: enabled,
                                        nombreCompleto: nombreCompleto,
                                        role: rol,
                                        grupo: grupoSeleccionado,
                                        coordinador: coordinador,
                                    };
                                    saveUser(editUsuario);
                                } else {
                                    const newUsuario: Usuario = {
                                        tipo: 1,
                                        correo: correo,
                                        enabled: enabled,
                                        nombreCompleto: nombreCompleto,
                                        role: rol,
                                        id: props.usuario.id,
                                        grupo: grupoSeleccionado,
                                        coordinador: coordinador,
                                    };
                                    createUser(newUsuario);
                                }
                            }}>
                        {
                            loading ? <>
                                <label><span className="spinner-border spinner-border-sm" role="status"
                                             aria-hidden="true"/>
                                    Creando usuario...</label>
                            </> : <>Aceptar</>
                        }
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}