import React, {useCallback, useEffect, useState} from "react";
import {Accordion, Alert, Button, Col, Container, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {actualizarCotizaciones, doLogin, getCotizacionesByGroupUuid} from "../../services/conem-services";
import PublicNavbar from "./PublicNavbar";
import {formatoMoneda} from "../../utils";
import {Servicio} from "../../types/servicio";
import ConfirmacionModal from "../common/ConfirmacionModal";
import LoadingSpinner from "../common/LoadingSpinner";

export default function CotizacionesPage() {

    const navigate = useNavigate();

    const [error, setError] = useState(true);
    const [message, setMessage] = useState<string | null>(null);
    const {id} = useParams();

    const [showMensajeDespedida, setShowMensajeDespedida] = useState(false)

    const [botonAceptarVisible, setBotonAceptarVisible] = useState<boolean>(true);

    const [cotizacionesList, setCotizacionesList] = useState<Servicio[]>([]);
    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState(0);


    const [showConfirmCancel, setShowConfirmCancel] = useState<boolean>(false);
    const [showConfirmAccept, setShowConfirmAccept] = useState<boolean>(false);


    useEffect(() => {
        try {
            //@ts-ignore
            let gId = atob(id);
            fetchData(gId).catch(error => console.log(error));
        } catch (e) {
            setError(true);
            setMessage('Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde');
        }
    }, []);

    const getTotal = (servicio: Servicio[]) => {
        return servicio.reduce((accumulator, currentValue: Servicio) => {
            return accumulator + (currentValue.aceptado ? currentValue.total : 0);
        }, 0);
    }

    const fetchData = useCallback(async (groupId: string) => {
        await getCotizacionesByGroupUuid(groupId).then(data => {
            setError(false);
            if (data && data.length > 0) {
                const newCotizacionesList = data.map((item: Servicio, idx: number) => {
                    item.aceptado = true;
                    return item;
                })
                const total = getTotal(newCotizacionesList);
                setCotizacionesList(newCotizacionesList);
                setTotal(total);
            } else {
                setError(true);
                setMessage('Oops!  lo sentimos la página que busca no existe');
            }
        }).catch(err => {
            setError(true);
            setMessage('Oops!  al parecer ha ocurrido un error, por favor inténtelo más tarde');
        });
    }, []);

    const showErrorMessage = (message: string) => {
        setError(true);
        setMessage(message);
    }

    const aceptarCotizaciones = () => {
        setLoading(true);
        //@ts-ignore
        let grupoId = atob(id);
        actualizarCotizaciones(grupoId, cotizacionesList).then(
            resp => {
                if (resp && resp.username) {
                    if (resp.password) {
                        doLogin(resp.username, resp.password).then(resp => {
                            navigate("/home");
                        })
                    } else {
                        navigate("/");
                    }
                }
            }
        ).catch((e) => {
            showErrorMessage('Lo sentimos ha ocurrido un error al intentar guardar la información, por favor inténtelo más tarde');
        }).finally(() => setLoading(false))

    }

    const cancelarCotizaciones = async () => {
        try {
            setLoading(true);
            // @ts-ignore
            let gId = atob(id);
            for (let i = 0; i < cotizacionesList.length; i++) {
                let proposal = cotizacionesList[i];
                proposal.aceptado = false;
                cotizacionesList[i] = {...proposal};
            }
            await actualizarCotizaciones(gId, cotizacionesList);
            setShowMensajeDespedida(true)
        } catch (e) {
            showErrorMessage('Lo sentimos ha ocurrido un error al intentar guardar la información, por favor inténtelo más tarde');
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <LoadingSpinner show={loading}/>
            <PublicNavbar></PublicNavbar>
            {showMensajeDespedida &&
                <Container style={{paddingTop: "100px"}}>
                    <center>
                        <h2>Agradecemos nos haya contactado, esperamos pronto poder colaborar juntos.</h2>
                    </center>
                </Container>
            }
            {
                error && cotizacionesList.length===0 &&
                <Container style={{paddingTop: "100px"}}>
                    <center>
                        <h2>{message}</h2>
                    </center>
                </Container>
            }
            {
                !showMensajeDespedida && cotizacionesList.length>0 &&
                <>
                    <div className="d-flex justify-content-center">
                        <div style={{width: "70%", marginTop: "20px"}}>
                            <p className="fs-6">
                                Estimado cliente a continuación se muestran cada una de las cotizaciones de
                                servicio
                                solicitadas además podrá ver mayor detalle de la cotización que desee descargando el
                                documento en
                                formato pdf
                                haciendo click en el botón <span
                                className="fw-semibold">"Descargar documento"</span>.
                            </p>
                            {
                                cotizacionesList.length > 1 &&
                                <p>
                                    * En caso de requerir excluir una cotización específica puede desmarcar dicha
                                    cotización con el
                                    botón de selección ubicado al lado izquierdo del número de folio.
                                </p>
                            }
                            <hr/>
                            {
                                (error && message) &&
                                <div style={{marginTop: '10px'}}>
                                    <Alert key="danger" variant="danger" onClose={() => {
                                        setError(false);
                                        setMessage(null);
                                    }} show={true}
                                           style={{fontSize: '0.9em'}}
                                           dismissible>
                                        {message}
                                    </Alert>
                                </div>
                            }

                            <Accordion defaultActiveKey="0">
                                {
                                    cotizacionesList.map((cotizacion: Servicio, index: number) => {
                                        return <Accordion.Item eventKey={`${index}`}>
                                            <Accordion.Header>
                                                <div>
                                                    <input className="form-check-input" type="checkbox"
                                                           checked={cotizacion.aceptado}
                                                           onChange={(e) => {
                                                               const newCotizacionesList = cotizacionesList.map((item: Servicio, idx: number) => {
                                                                   if (idx === index) {
                                                                       item.aceptado = e.target.checked;
                                                                   }
                                                                   return item;
                                                               })
                                                               const total = getTotal(newCotizacionesList);
                                                               setBotonAceptarVisible(total > 0);
                                                               setTotal(total);
                                                               setCotizacionesList(newCotizacionesList);
                                                           }}/>
                                                </div>
                                                <div style={{marginLeft: '10px'}}>
                                                    <span>Folio: </span><span
                                                    className="fw-bold"> {cotizacion.folio} </span>
                                                </div>

                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    <Col sm={cotizacionesList.length > 1 ? '8' : '12'}>
                                                        <p>
                                                            Servicio solicitado: <span
                                                            className="fs-6 fw-bold">{cotizacion.descripcion}</span>
                                                        </p>
                                                        <p>
                                                            Nombre del contacto: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente.nombreCompleto}</span>
                                                        </p>
                                                        <p>
                                                            Nombre comercial: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente?.detalle?.nombreComercial}</span>
                                                        </p>
                                                        <p>
                                                            Dirección: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente?.detalle?.direccion}</span>
                                                        </p>
                                                        <p>
                                                            Metros de construcción: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente?.detalle?.metrosConstruccion ? cotizacion.cliente.detalle.metrosConstruccion : 'No especificado'}</span>
                                                        </p>
                                                        <p>
                                                            Número de empleados: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente?.detalle?.numeroEmpleados ? cotizacion.cliente.detalle.numeroEmpleados : 'No especificado'}</span>
                                                        </p>
                                                        <p>
                                                            Razón Social: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente?.detalle?.razonSocial ? cotizacion.cliente.detalle.razonSocial : 'No especificado'}</span>
                                                        </p>
                                                        <p>
                                                            Domicilio fiscal: <span
                                                            className="fs-6 fw-bold">{cotizacion.cliente?.detalle?.domicilioFiscal ? cotizacion.cliente.detalle.domicilioFiscal : 'No especificado'}</span>
                                                        </p>
                                                    </Col>
                                                    {
                                                        cotizacionesList.length > 1 &&
                                                        <Col>
                                                            <div className='d-flex justify-content-end'
                                                                 style={{marginTop: '15px'}}>
                                                                <span className='fs-6'>Precio: <span
                                                                    className='fw-bold'>{formatoMoneda(cotizacion.precio)}</span></span>
                                                            </div>
                                                            {
                                                                (cotizacion.descuento === null || cotizacion.descuento === undefined) &&
                                                                <div className='d-flex justify-content-end'
                                                                     style={{marginTop: '15px'}}>
                                                                <span
                                                                    className='fs-6'>Descuento: <span
                                                                    className='fw-bold'
                                                                    style={{textDecoration: 'line-through'}}>{formatoMoneda(cotizacion.descuento)}</span></span>
                                                                </div>
                                                            }
                                                            <div className='d-flex justify-content-end'
                                                                 style={{marginTop: '15px'}}>
                                                                <span
                                                                    className='fs-6'>IVA: <span
                                                                    className='fw-bold'>{formatoMoneda(cotizacion.iva)}</span></span>
                                                            </div>
                                                            <hr/>
                                                            <div className='d-flex justify-content-end'
                                                                 style={{marginTop: '15px'}}>
                                                                <span
                                                                    className='fs-6'>Subtotal: <span
                                                                    className='fw-bold'>{formatoMoneda(cotizacion.total)}</span></span>
                                                            </div>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className='d-flex justify-content-end'
                                                             style={{marginTop: '15px'}}>
                                                            <Button size='sm' variant='dark'
                                                                    style={{width: '200px'}} onClick={() => {
                                                                fetch(`/public/cotizaciones/${cotizacion.id}/download`)
                                                                    .then(response => {
                                                                        response.blob().then(blob => {
                                                                            let url = window.URL.createObjectURL(blob);
                                                                            let a = document.createElement('a');
                                                                            a.href = url;
                                                                            a.download = `${cotizacion.folio}.pdf`;
                                                                            a.click();
                                                                        });
                                                                    });
                                                            }}>
                                                                <i className="bi bi-filetype-pdf"></i> Descargar
                                                                documento
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    })
                                }

                            </Accordion>

                        </div>
                    </div>

                    {
                        cotizacionesList.length == 1 &&
                        <>
                            <div className="d-flex justify-content-end" style={{width: "85%", marginTop: '20px'}}>
                                <p className="fs-4 fw-bold">
                                    Precio: {formatoMoneda(cotizacionesList[0].precio)}
                                </p>
                            </div>
                            {
                                cotizacionesList[0].descuento > 0 &&
                                <div className="d-flex justify-content-end" style={{width: "85%"}}>
                                    <p className="fs-4 fw-bold">
                                        Descuento: <span
                                        style={{textDecoration: 'line-through'}}>{formatoMoneda(cotizacionesList[0].descuento)}</span>
                                    </p>
                                </div>
                            }
                            <div className="d-flex justify-content-end" style={{width: "85%", marginTop: '5px'}}>
                                <p className="fs-4 fw-bold">
                                    IVA: {formatoMoneda(cotizacionesList[0].iva)}
                                </p>
                            </div>
                        </>
                    }


                    <div className="d-flex justify-content-end" style={{
                        width: "70%",
                        marginTop: '10px',
                        marginBottom: '20px',
                        marginLeft: '15%',
                        borderTop: "1px solid rgba(0, 0, 0, 0.1)"
                    }}>
                        <p className="fs-4 fw-bold">
                            Total: {formatoMoneda(total)}
                        </p>
                    </div>
                    <div className="d-flex justify-content-end"
                         style={{
                             paddingTop: "20px",
                             marginBottom: '20px',
                             marginLeft: '15%',
                             borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                             width: "70%"
                         }}>
                        <Button variant="danger" size='sm' style={{width: "230px", padding: '10px'}}
                                onClick={() => {
                                    setShowConfirmCancel(true);
                                }}>
                            Cancelar la cotización
                        </Button>
                        {
                            botonAceptarVisible &&
                            <Button size='sm' variant="dark" style={{width: "230px", padding: '10px'}}
                                    onClick={async (e) => {
                                        setShowConfirmAccept(true);
                                    }}>
                                Aceptar la cotización
                            </Button>
                        }
                    </div>

                </>
            }


            <ConfirmacionModal
                title='Confirmación'
                message={['Si aún no tiene una cuenta con nosotros al aceptar la cotización será redireccionado al panel de servicios de CONEM con una sesión temporal para proporcionar información y archivos requeridos, en caso de tener una cuenta existente se solicitará que inicie sesión con sus credenciales.']}
                show={showConfirmAccept}
                onAccept={() => {
                    setShowConfirmAccept(false);
                    aceptarCotizaciones()
                }}
                onCancel={() => {
                    setShowConfirmAccept(false);
                }}/>

            <ConfirmacionModal
                title='Confirmación'
                message={['Al cancelar la solicitud se eliminarán todas las cotizaciones que pertenecen a esta solicitud.', '¿Desea continuar con la cancelación?']}
                show={showConfirmCancel}
                onAccept={() => {
                    setShowConfirmCancel(false);
                    cancelarCotizaciones();
                }}
                onCancel={() => {
                    setShowConfirmCancel(false);
                }}/>

        </>

    )
}