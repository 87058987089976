import React, {useState} from "react";
import Dashboard from "./estadisticas/Dashboard";
import CotizacionList from "./cotizacion/CotizacionList";
import FormNuevaCotizacion from "./cotizacion/FormNuevaCotizacion";
import ServicioList from "./servicio/ServicioList";
import CatalogoServicioList from "./catalogo-servicio/CatalogoServicioList";
import UsuarioList from "./usuario/UsuarioList";
import DropboxConfigPage from "./dropbox/DropboxConfigPage";
import EventosIndex from "./evento/EventosIndex";
import NotificacionesIndex from "./notificacion/NotificacionesIndex";

import {
    isAdmin,
    isCalidad,
    isCliente,
    isCoordinador,
    isOperativo,
    isRequeridoCambioContrasenia,
    isVentas
} from "../utils";
import Navbar from "./common/Navbar";
import Sidebar from "./common/Sidebar";
import FormNuevaContrasenia from "./login/FormNuevaContrasenia";
import {Container} from "react-bootstrap";

const Home = () => {

    const [selectedMenuItem, setSelectedMenuItem] = useState('charts');
    const [showSidebar,setShowSidebar]=useState<boolean>(true);

    return (
        <>
            {
                isRequeridoCambioContrasenia() ?
                    <>
                        <Navbar></Navbar>
                        <div style={{marginTop: '20px'}}>
                            <FormNuevaContrasenia/>
                        </div>
                    </> :
                    <>
                        <Navbar></Navbar>
                        <div className="d-flex flex-row workspace">
                            {
                                (isAdmin() || isVentas()) && showSidebar &&
                                <Sidebar selectedMenuItem={selectedMenuItem} onChangeSelectedMenuItem={(e) => {
                                    setSelectedMenuItem(e);
                                }}/>
                            }
                            <Container>
                                {
                                    (isCliente() || isCalidad() || isOperativo() || isCoordinador()) &&
                                    <ServicioList hideSidebar={()=>setShowSidebar(false)}
                                                  showSidebar={()=>setShowSidebar(true)}
                                    />
                                }
                                {
                                    ((isAdmin() || isVentas()) && selectedMenuItem === 'charts') && <Dashboard/>
                                }
                                {
                                    ((isAdmin() || isVentas()) && selectedMenuItem === 'cotizacion') &&
                                    <FormNuevaCotizacion/>
                                }
                                {
                                    ((isAdmin() || isVentas()) && selectedMenuItem === 'consultas') &&
                                    <CotizacionList/>
                                }
                                {
                                    ((isAdmin() || isVentas()) && selectedMenuItem === 'servicios') && <ServicioList
                                        hideSidebar={()=>setShowSidebar(false)}
                                        showSidebar={()=>setShowSidebar(true)}
                                    />
                                }
                                {
                                    ((isAdmin() || isVentas()) && selectedMenuItem === 'confplan') &&
                                    <CatalogoServicioList/>
                                }
                                {
                                    (isAdmin() && selectedMenuItem === 'usuarios') && <UsuarioList/>
                                }
                                {
                                    (isAdmin() && selectedMenuItem === 'dropbox') && <DropboxConfigPage/>
                                }
                                {
                                    (isAdmin() && selectedMenuItem === 'eventos') && <EventosIndex/>
                                }
                                {
                                    ((isAdmin() || isVentas()) && selectedMenuItem === 'notificaciones') &&
                                    <NotificacionesIndex/>
                                }
                            </Container>
                        </div>
                    </>
            }
        </>
    )
}
export default Home;