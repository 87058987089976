import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/css/bootstrap.min.css"
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Login from "./components/login/Login";
import ErrorPage from "./ErrorPage";
import Home from "./components/Home";
import CotizacionesPage from "./components/publico/CotizacionesPage";
import Protected from './components/Protected';
import {getUsuarioSesion} from "./utils";
import FormCambiarContrasenia from "./components/login/FormCambiarContrasenia";
import ServicioDetalle from './components/publico/ServicioDetalle';
import PageEvaluacionSatisfaccion from "./components/publico/PageEvaluacionSatisfaccion";

function isAuthenticated() {
    return getUsuarioSesion() !== null;
}

const router = createBrowserRouter([
    {
        path: "/home",
        element: <Protected redirectPath='/' isAuthenticated={isAuthenticated}>
            <Home/>
        </Protected>,
        errorElement: <ErrorPage/>,
    }, {
        path: "/",
        element: <Login/>
    }, {
        path: "change/:id",
        element: <FormCambiarContrasenia/>
    },
    {
        path: "my/:id",
        element: <CotizacionesPage/>
    },
    {
        path: "services/:id",
        element: <ServicioDetalle/>
    },
    {
        path: "evaluaciones/:id",
        element: <PageEvaluacionSatisfaccion/>
    },
    {
        path: "*",
        element:
            <Protected redirectPath='/' isAuthenticated={false}>
                <Home/>
            </Protected>
    },
]);


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
        <RouterProvider router={router}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
