import {Archivo} from "../../types/servicio";
import {Col, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import {byteConverter, formatoFechaTiempo, getUsuarioSesion, truncarTexto} from "../../utils";
import DeleteButton from "../common/DeleteButton";

export default function ArchivoItem(props: {
    archivo: any,
    servicioId: string,
    documentoId: string,
    statusServicio: string,
    seleccionable: boolean,
    eliminable: boolean,
    onDelete?: (archivo: Archivo) => void,
    onSelect?: (checked: boolean) => void
}) {

    const [selected, setSelected] = useState<boolean>(false);

    return (<>
        <div className='rounded' style={{marginTop: '10px', padding: '5px', background: "#f7f8fa",border:"1px solid #d9dbde"}}>
            <Row>
                <Col>
                    <div className='d-inline-flex'>
                        {
                            (props.archivo.fechaSubido && props.seleccionable && props.onSelect) &&
                            <div style={{marginTop: '4px', marginRight: '5px'}}>
                                <input className="form-check-input" type="checkbox"
                                       checked={selected}
                                       onChange={(e) => {
                                           setSelected(e.target.checked);
                                           if (props.onSelect)
                                               props.onSelect(e.target.checked)
                                       }}/>
                            </div>
                        }
                        {
                            (!props.archivo.fechaSubido && getUsuarioSesion() !== null) ?
                                <div className='fs-6' style={{marginTop: '3px', color: 'gray'}}>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </div>
                                :
                                <span
                                    className='fs-5 bi bi-file-earmark'/>
                        }

                        <div style={{marginLeft: '3px'}}>
                            <label className="file-name-label"
                                   onClick={() => {
                                       if (getUsuarioSesion() !== null && props.archivo.url) {
                                           fetch(props.archivo.url)
                                               .then(response => {
                                                   response.blob().then(blob => {
                                                       let url = window.URL.createObjectURL(blob);
                                                       let a = document.createElement('a');
                                                       a.href = url;
                                                       a.download = `${props.archivo.nombre}`;
                                                       a.click();
                                                   });
                                               });
                                       }
                                   }}>
                                {props.archivo.name ? truncarTexto(props.archivo.name, 100) : truncarTexto(props.archivo.nombre, 100)}
                            </label>
                        </div>


                    </div>
                </Col>
            </Row>
            <Row>
                <Col md='10'>
                    {
                        getUsuarioSesion() !== null &&
                        <>
                            <div className='file-size-label' style={{padding: '5px', paddingLeft: '22px'}}>
                                <div><label>
                                    {props.archivo.tamanio ? `Tamaño: ${byteConverter(props.archivo.tamanio, 2)}` : ''}
                                </label></div>
                                <label>{props.archivo.fechaSubido ? formatoFechaTiempo(props.archivo.fechaSubido) : '------'} - {props.archivo.subidoPor ? `${props.archivo.subidoPor}` : '------'}</label>
                            </div>
                        </>
                    }
                </Col>
                {
                    props.eliminable && <Col>
                        <div className='d-flex justify-content-end'>
                            <DeleteButton onClick={() => {
                                if (props.archivo?.subidoPor && props.onDelete) {
                                    // @ts-ignore
                                    props.onDelete(props.archivo);
                                }
                            }}/>
                        </div>
                    </Col>
                }
            </Row>
        </div>

    </>)
}